<template>
  <div style="height: 200px; position: relative">
    <vue-speedometer
      :currentValueText="numberEnfasis ? '' : display"
      :minValue="usingMin"
      :maxValue="usingMax"
      :width="small ? 200 : 320"
      :segments="5"
      needleColor="#000000"
      :value="speedometerValue"
      :forceRender="true"
    />
    <div v-if="numberEnfasis" class="d-flex flex-column enfasis">
      <span>{{ customText }}</span>
      <span class="number">{{ speedometerValue }}</span>
    </div>
  </div>
</template>

<script>
import NumberPatternMixin from "@/support/utils/numberPattern.mixin";

export default {
  name: "GeneralSpeedometer",
  mixins: [NumberPatternMixin],
  props: {
    value: {
      required: true,
      type: Number,
    },
    customText: {
      type: String,
    },
    small: {
      type: Boolean,
      default: false,
    },
    max: {
      type: Number,
      default: 10
    },
    min: {
      type: Number,
      default: 5
    },
    changeMinMax: {
      type: Boolean,
      default: false,
    },
    numberEnfasis:{
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      speedometerValue: 0,
      usingMin: this.min,
      usingMax: this.max,
    };
  },
  watch: {
    value:function (newValue) {
      if(typeof(newValue) === 'number')
        this.updateComponent()
    },
    min:function (newMin) {
      if(typeof(newMin) === 'number'&& typeof(this.max) === 'number' && this.changeMinMax)
        this.updateComponent()
    },
    max:function (newMax) {
      if(typeof(newMax) === 'number'&& typeof(this.min) === 'number' && this.changeMinMax)
        this.updateComponent()
    },
  },
  computed: {
    display: function () {
      let value = `${this.customText} : `;
      if(isNaN(this.value)){
        value += " --"
        return value;
      }
      if (this.value) {
        value += this.value === 0 ? "0" : `${this.speedometerValue}`;
      } else {
        value += "0";
      }
      return value;
    },
  },
  methods: {
    updateValue() {
      let value = this.value ? this.value : this.min;

      this.speedometerValue = Number(this.decimalPattern(value));
    },
    updateMinMax(){
      if(!this.min && !this.max){
        this.usingMin = 0;
        this.usingMax = 5;
      }
      else if(this.min === this.max){
        this.usingMin = this.min -5
        this.usingMax = this.max;
      }
      else{
        this.usingMin = this.min,
        this.usingMax = this.max
      }
      this.usingMin = Number(this.decimalPattern(this.usingMin))
      this.usingMax = Number(this.decimalPattern(this.usingMax))
    },
    updateComponent(){
      this.updateValue();
      this.updateMinMax();
    }
  },
  mounted() {
    this.updateValue();
    this.updateMinMax();
  },
};
</script>

<style scoped>
.enfasis{
  position: absolute;
  top:180px;
  width: 100%;
  text-align: center;
  font-weight: bold;
}
.number{
  font-size: 30px;
}

</style>
