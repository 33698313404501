import http from "@/services/http.service";

const apiUrl = "/iql-calculation";

class IqlCalculationsService {
  static getGeneral(farmId, harvest) {
    return http.get(`${apiUrl}/general/farms/${farmId}/harvests/${harvest}`);
  }
  static getNational(harvest, filters) {
    return http.get(`${apiUrl}/national/harvests/${harvest}`, { params: { ...filters } });
  }
  static getRegional(successAdvisorId, harvest, filters) {
    return http.get(`${apiUrl}/regional/as/${successAdvisorId}/harvests/${harvest}`, { params: { ...filters } });
  }
}

export default IqlCalculationsService;
