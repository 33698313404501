<template>
  <div style="width: 100%">
    <vue-apex-chart
      ref="customChart"
      type="bar"
      height="250"
      :options="chartOptions"
      :series="series" />
  </div>
</template>

<script>
export default {
  name: "ApexChart",
  props: {
    series: {
      type: Array,
      required: true,
    },
    categories: {},
    min: {},
    max: {},
    tickAmount: {},
    legend: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({}),
  computed: {
    chartOptions() {
      return {
        chart: {
          type: "bar",
          toolbar: {
            show: false,
          },
          parentHeightOffset: 15,
        },

        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 6,
            colors: {},
            dataLabels: {
              position: "top",
            },
          },
        },

        colors: ["#fe5900", "#0f3f62", "#9C27B0", "#fff3", "#FFE15D"],

        dataLabels: {
          formatter: (value) => (value ? Number(value).toFixed(1) : "N/A"),
          style: {
            fontWeight: "bold",
            colors: ["#ffffff"],
          },
          offsetY: -18,
          background: {
            foreColor: "#000000",
            enabled: true,
            padding: 4,
            borderRadius: 2,
            opacity: 0.9,
          },
        },

        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },

        fill: {
          opacity: 1,
        },

        legend: {
          show: this.legend,
          showForSingleSeries: true,
          markers: {
            radius: 12,
            // offsetX: -5,
            height: 8,
            width: 8,
          },
          labels: {
            useSeriesColors: true,
          },
        },

        tooltip: {
          enabled: false,
        },
      };
    },
  },
  methods: {
    updateChart() {
      this.$refs.customChart.updateSeries(this.series);
      this.$refs.customChart.updateOptions({
        xaxis: {
          categories: this.categories,
        },
        yaxis: {
          min: this.min,
          max: this.max,
          tickAmount: this.tickAmount,
          labels: {
            formatter: (value) => (value ? Number(value).toFixed(1) : "N/A"),
          },
        },
      });
    },
  },
  mounted() {
    this.updateChart();
  },
};
</script>
