import HarvestsService from "./harvests.service";

class Harvest {
  constructor(payload = {}) {
    Object.assign(this, {
      ...Harvest.build(payload),
    });
  }

  static build({ harvest = "" }) {
    return {
      harvest,
    };
  }

  static newData(payload) {
    return new Harvest(payload);
  }

  static all() {
    return new Promise((resolve, reject) => {
      HarvestsService.all().then(
        (success) => {
          const harvests = success;
          resolve(harvests.data);
        },

        (error) => {
          reject(error);
        }
      );
    });
  }
}

export default Harvest;
