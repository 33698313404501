<template>
  <h2
      :class="`pa-0 font-weight-bold text-sm-left ${smallMargin? 'mb-0' : 'mb-8'}`"
  >
    {{ label }}
  </h2>
</template>

<script>
export default {
  name: "CustomCardTitle",
  props: {
    label: {
      type: String,
      required: true,
    },
    smallMargin:{
      type: Boolean,
      default: false,
    }
  }
}
</script>

<style scoped>
  h2 {
    font-size: 18px;
    color: #212020;
    opacity: 1;
  }
</style>