import http from "@/services/http.service";

const apiUrl = "/harvests";

class HarvestsService {
  static all() {
    return http.get(`${apiUrl}`);
  }
}

export default HarvestsService;
