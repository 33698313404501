<template>
    <div  
        class="d-flex filter flex-column align-center" 
        :class="active?'active':''"
        @click="toggleFarm"
    >
        <div 
            class="color" 
            :style="{'background-color':color}" 
        ></div>
        <p class="farm-name mt-2 mb-0" :style="{color:color}">
            {{name}}
        </p>        
    </div>
</template>

<script>
    export default {
        props: {
            name: {
                type: String,
                default: '' 
            },
            color: {
                type: String,
                default: '' 
            },
        },
        data() {
            return {
                active: true,
            }
        },
        methods: {
            toggleFarm() {
                this.active = !this.active;
                this.$emit('toggle', this.active);
            }
        },       
    }
</script>

<style lang="scss" scoped>
.filter{
    cursor: pointer;
    opacity: 0.4;
    .color{
        height: 1rem;
        width: 1rem;
    }
    .farm-name{
        line-height: normal;
        text-align: center;
        word-break: normal;
        font-size: 12px;        
    }

    &.active{
        opacity: 1;
    }
}

</style>