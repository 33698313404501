var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('custom-h1',{staticClass:"grey--text text--darken-2 pt-5",attrs:{"label":"Análise de Desempenho"}}),_c('search-bar',{attrs:{"hasTextInput":false,"hasClearFilters":false,"hasActionButton":true,"noSecondaryInput":false,"noTertiaryInput":false,"actionButtonLabel":"Buscar"},on:{"externalAction":function($event){return _vm.searchFarm()}}},[(_vm.authoritySlug !== 'producer')?_c('simple-select',{staticClass:"mr-4",attrs:{"items":_vm.producers,"placeholder":"Produtor","itemText":"user.name","itemValue":"producer_id","returnObject":"","height":"auto","menuProps":{ bottom: true, offsetY: true, closeOnClick: true }},on:{"input":_vm.loadFarms},model:{value:(_vm.selectedProducer),callback:function ($$v) {_vm.selectedProducer=$$v},expression:"selectedProducer"}}):_vm._e(),_c('template',{slot:"tertiary-input"},[_c('simple-select',{staticClass:"mr-4",attrs:{"items":_vm.farms,"placeholder":"Fazenda","itemText":"name","loading":_vm.loadingFarms,"itemValue":"id","returnObject":"","height":"auto","menuProps":{ bottom: true, offsetY: true, closeOnClick: true }},model:{value:(_vm.selectedFarm),callback:function ($$v) {_vm.selectedFarm=$$v},expression:"selectedFarm"}})],1)],2),(
      Object.keys(_vm.rankingPerformanceAnalysis).length > 0 &&
      _vm.farmsSelected.length > 0
    )?_c('div',[_c('v-card',{staticClass:"mb-12 rounded-xl"},[_c('v-card-title',{staticClass:"yellow-border"},[(_vm.selectedFertilityType === 'FG')?_c('v-container',{key:_vm.componentKeyFertility,staticClass:"my-2 custom-container"},[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-start",attrs:{"cols":"12","md":"4"}},[_c('custom-card-title',{staticClass:"grey--text text--darken-2",staticStyle:{"padding-left":"1.7rem !important"},attrs:{"label":"Registro de Fertilidade"}})],1),_c('v-col',{staticClass:"d-flex py-0 pb-4 justify-end",attrs:{"md":"8"}},[_c('v-row',{staticClass:"mt-2 px-2"},[_c('v-col',{staticClass:"pa-0",attrs:{"md":"5","cols":"12"}},[_c('simple-select',{staticClass:"mr-2 mb-2",attrs:{"items":_vm.fertilityTypes,"itemText":"text","itemValue":"value","height":"auto","menuProps":{
                      bottom: true,
                      offsetY: true,
                      closeOnClick: true,
                    }},model:{value:(_vm.selectedFertilityType),callback:function ($$v) {_vm.selectedFertilityType=$$v},expression:"selectedFertilityType"}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"md":"4","cols":"12"}},[_c('simple-select',{staticClass:"mr-2 mb-2",attrs:{"items":_vm.filter,"itemText":"text","itemValue":"text","height":"auto","menuProps":{
                      bottom: true,
                      offsetY: true,
                      closeOnClick: true,
                    }},on:{"custom-change":function($event){return _vm.changeFertility()}},model:{value:(_vm.selectedFilterFertility),callback:function ($$v) {_vm.selectedFilterFertility=$$v},expression:"selectedFilterFertility"}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"md":"3","cols":"12"}},[_c('simple-select',{staticClass:"mr-2 mb-2",attrs:{"items":this.harvests.map((item) => item.harvest),"itemText":"harvest","itemValue":"harvest","placeholder":"Safra","height":"auto","menuProps":{
                      bottom: true,
                      offsetY: true,
                      closeOnClick: true,
                    }},on:{"custom-change":function($event){return _vm.changeFertility()}},model:{value:(_vm.harvestFertility),callback:function ($$v) {_vm.harvestFertility=$$v},expression:"harvestFertility"}})],1)],1)],1)],1),(_vm.farmsIdWithFertility.length > 4 && _vm.seriesFertility)?_c('v-container',[_c('h4',[_vm._v("Filtrar Fazendas")]),_c('v-container',{staticClass:"filters d-flex justify-center flex-wrap"},[_vm._l((_vm.farmsIdWithFertility),function(farm,index){return _c('FarmFilter',{key:'farmFilter' + index,attrs:{"name":_vm.seriesFertility[index].name,"color":_vm.seriesFertility[index].color},on:{"toggle":(e) =>
                    _vm.toggleFarm(e, 'fertility', _vm.seriesFertility[index].name)}})}),_c('FarmFilter',{attrs:{"name":_vm.seriesFertility[_vm.seriesFertility.length - 1].name,"color":_vm.seriesFertility[_vm.seriesFertility.length - 1].color},on:{"toggle":(e) => _vm.toggleFarm(e, 'fertility', _vm.farm)}})],2)],1):_vm._e(),_vm._l((_vm.categoriesFertility),function(category,key){return _c('v-container',{key:'fertility' + key,style:({
              width: `${
                _vm.farmsIdWithFertility.length < 4 ? category.length * 25 : '100'
              }%`,
            }),attrs:{"fluid":""}},[_c('apex-chart',{attrs:{"series":_vm.filteredSeriesFertility.slice(
                  key *
                    (_vm.farmsIdWithFertility.length -
                      _vm.removedSeriesFertility.length +
                      1),
                  key *
                    (_vm.farmsIdWithFertility.length -
                      _vm.removedSeriesFertility.length +
                      1) +
                    (_vm.farmsIdWithFertility.length -
                      _vm.removedSeriesFertility.length) +
                    1
                ),"categories":category,"min":0,"max":10,"tickAmount":5}})],1)})],2):_vm._e(),(_vm.selectedFertilityType === 'FT')?_c('v-container',{key:_vm.componentKeyFertilityField,staticClass:"my-2 custom-container"},[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-start",attrs:{"cols":"12","md":"5"}},[_c('custom-card-title',{staticClass:"grey--text text--darken-2",staticStyle:{"padding-left":"1.7rem !important"},attrs:{"label":"Registro de Fertilidade por Talhão"}})],1),_c('v-col',{staticClass:"d-flex py-0 pb-4 justify-end",attrs:{"md":"7"}},[_c('v-row',{staticClass:"mt-2 px-2"},[_c('v-col',{staticClass:"pa-0",attrs:{"md":"7","cols":"12"}},[_c('simple-select',{staticClass:"mr-2 mb-2",attrs:{"items":_vm.fertilityTypes,"itemText":"text","itemValue":"value","height":"auto","menuProps":{
                      bottom: true,
                      offsetY: true,
                      closeOnClick: true,
                    }},model:{value:(_vm.selectedFertilityType),callback:function ($$v) {_vm.selectedFertilityType=$$v},expression:"selectedFertilityType"}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"md":"5","cols":"12"}},[_c('simple-select',{staticClass:"mr-2 mb-2",attrs:{"items":this.harvests.map((item) => item.harvest),"itemText":"harvest","itemValue":"harvest","placeholder":"Safra","height":"auto","menuProps":{
                      bottom: true,
                      offsetY: true,
                      closeOnClick: true,
                    }},on:{"custom-change":function($event){return _vm.changeFertilityByField()}},model:{value:(_vm.selectedFertilityHarvestByField),callback:function ($$v) {_vm.selectedFertilityHarvestByField=$$v},expression:"selectedFertilityHarvestByField"}})],1)],1)],1)],1),_c('v-row',{staticClass:"mt-2 px-2"},[_c('v-col',{staticClass:"pa-0",attrs:{"md":"5","offset-md":"4","cols":"12"}},[_c('simple-select',{staticClass:"mr-2 mb-2",attrs:{"items":_vm.farmsSelected,"itemText":"name","itemValue":"id","placeholder":"Selecione uma fazenda","height":"auto","menuProps":{
                  bottom: true,
                  offsetY: true,
                  closeOnClick: true,
                },"disabled":!_vm.selectedFertilityHarvestByField},on:{"custom-change":function($event){return _vm.getFertilityByField()}},model:{value:(_vm.selectedFertilityFarmByField),callback:function ($$v) {_vm.selectedFertilityFarmByField=$$v},expression:"selectedFertilityFarmByField"}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"md":"3","cols":"12"}},[_c('simple-select',{staticClass:"mr-2 mb-2",attrs:{"items":_vm.fertilityIndexes.map((item) => item.index_name),"placeholder":"Selecione um índice","height":"auto","menuProps":{
                  bottom: true,
                  offsetY: true,
                  closeOnClick: true,
                },"disabled":!_vm.selectedFertilityHarvestByField},on:{"custom-change":function($event){return _vm.getFertilityByField()}},model:{value:(_vm.selectedFertilityIndexByField),callback:function ($$v) {_vm.selectedFertilityIndexByField=$$v},expression:"selectedFertilityIndexByField"}})],1)],1),(_vm.seriesFertilityField.length > 0)?_c('v-container',{style:({
              width: `${
                _vm.farmsIdWithFertility.length < 4 ? _vm.category.length * 25 : '100'
              }%`,
            }),attrs:{"fluid":""}},_vm._l((_vm.seriesFertilityField),function(serie,index){return _c('apex-chart',{key:'fertilityField' + index,attrs:{"series":serie,"categories":_vm.categoriesFertilityField,"min":0,"max":10,"tickAmount":5}})}),1):_vm._e()],1):_vm._e(),_c('v-container',{key:_vm.componentKey,staticClass:"my-2 custom-container"},[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-start",attrs:{"cols":"12","md":"4"}},[_c('custom-card-title',{staticClass:"grey--text text--darken-2",staticStyle:{"padding-left":"1.7rem !important"},attrs:{"label":"Registro de Manejo"}})],1),_c('v-col',{staticClass:"d-flex py-0 pb-4 justify-end",attrs:{"md":"8"}},[_c('v-row',{staticClass:"mt-2 px-2"},[_c('v-col',{staticClass:"pa-0",attrs:{"md":"4","cols":"12"}},[_c('simple-select',{staticClass:"mr-2 mb-2",attrs:{"items":_vm.filter,"itemText":"text","itemValue":"text","height":"auto","menuProps":{
                      bottom: true,
                      offsetY: true,
                      closeOnClick: true,
                    }},on:{"custom-change":function($event){return _vm.changeManagement()}},model:{value:(_vm.selectedFilterManagement),callback:function ($$v) {_vm.selectedFilterManagement=$$v},expression:"selectedFilterManagement"}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"md":"5","cols":"12"}},[_c('simple-select',{staticClass:"mr-2 mb-2",attrs:{"items":this.managementPhases,"itemText":"text","itemValue":"value","height":"auto","menuProps":{
                      bottom: true,
                      offsetY: true,
                      closeOnClick: true,
                    }},on:{"custom-change":function($event){return _vm.changeManagement()}},model:{value:(_vm.selectedManagementPhase),callback:function ($$v) {_vm.selectedManagementPhase=$$v},expression:"selectedManagementPhase"}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"md":"3","cols":"12"}},[_c('simple-select',{staticClass:"mr-2 mb-2",attrs:{"items":this.harvests.map((item) => item.harvest),"itemText":"harvest","itemValue":"harvest","placeholder":"Safra","height":"auto","menuProps":{
                      bottom: true,
                      offsetY: true,
                      closeOnClick: true,
                    }},on:{"custom-change":function($event){return _vm.changeManagement()}},model:{value:(_vm.harvestManagement),callback:function ($$v) {_vm.harvestManagement=$$v},expression:"harvestManagement"}})],1)],1)],1)],1),(
              _vm.farmsIdWithManagementPhase.length > 4 && _vm.seriesManagementPhase
            )?_c('v-container',[_c('h4',[_vm._v("Filtrar Fazendas")]),_c('v-container',{staticClass:"filters d-flex justify-center flex-wrap"},[_vm._l((_vm.farmsIdWithFertility),function(farm,index){return _c('FarmFilter',{key:'farmFilter' + index,attrs:{"name":_vm.seriesManagementPhase[index].name,"color":_vm.seriesManagementPhase[index].color},on:{"toggle":(e) =>
                    _vm.toggleFarm(
                      e,
                      'management',
                      _vm.seriesManagementPhase[index].name
                    )}})}),_c('FarmFilter',{attrs:{"name":_vm.seriesManagementPhase[_vm.seriesManagementPhase.length - 1].name,"color":_vm.seriesManagementPhase[_vm.seriesManagementPhase.length - 1]
                    .color},on:{"toggle":(e) => _vm.toggleFarm(e, 'management', _vm.farm)}})],2)],1):_vm._e(),_vm._l((_vm.categoriesManagementPhase),function(category,key){return _c('v-container',{key:'management' + key,style:({
              width: `${
                _vm.farmsIdWithManagementPhase.length < 4
                  ? category.length * 25
                  : '100'
              }%`,
            }),attrs:{"fluid":""}},[_c('apex-chart',{attrs:{"series":_vm.filteredSeriesManagement.slice(
                  key *
                    (_vm.farmsIdWithManagementPhase.length -
                      _vm.removedSeriesManagement.length +
                      1),
                  key *
                    (_vm.farmsIdWithManagementPhase.length -
                      _vm.removedSeriesManagement.length +
                      1) +
                    _vm.farmsIdWithManagementPhase.length -
                    _vm.removedSeriesManagement.length +
                    1
                ),"categories":category,"min":0,"max":10,"tickAmount":5}})],1)})],2),_c('v-container',{key:_vm.componentKeyFinancial,staticClass:"my-2 custom-container"},[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-start",attrs:{"cols":"12","md":"5"}},[_c('custom-card-title',{staticClass:"grey--text text--darken-2",staticStyle:{"padding-left":"1.7rem !important"},attrs:{"label":"Registro de Financeiro"}})],1),_c('v-col',{staticClass:"d-flex py-0 pb-4 justify-end",attrs:{"md":"7"}},[_c('v-row',{staticClass:"mt-2 px-2"},[_c('v-col',{staticClass:"pa-0",attrs:{"md":"7","cols":"12"}},[_c('simple-select',{staticClass:"mr-2 mb-2",attrs:{"items":_vm.filter,"itemText":"text","itemValue":"text","height":"auto","menuProps":{
                      bottom: true,
                      offsetY: true,
                      closeOnClick: true,
                    }},on:{"custom-change":function($event){return _vm.changeFinancial()}},model:{value:(_vm.selectedFilterFinancial),callback:function ($$v) {_vm.selectedFilterFinancial=$$v},expression:"selectedFilterFinancial"}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"md":"5","cols":"12"}},[_c('simple-select',{staticClass:"mr-2 mb-2",attrs:{"items":this.harvests.map((item) => item.harvest),"itemText":"harvest","itemValue":"harvest","placeholder":"Safra","height":"auto","menuProps":{
                      bottom: true,
                      offsetY: true,
                      closeOnClick: true,
                    }},on:{"custom-change":function($event){return _vm.changeFinancial()}},model:{value:(_vm.harvestFinancial),callback:function ($$v) {_vm.harvestFinancial=$$v},expression:"harvestFinancial"}})],1)],1)],1)],1),(_vm.farmsIdWithFinancial.length > 4 && _vm.seriesFinancial)?_c('v-container',[_c('h4',[_vm._v("Filtrar Fazendas")]),_c('v-container',{staticClass:"filters d-flex justify-center flex-wrap"},[_vm._l((_vm.farmsIdWithFinancial),function(farm,index){return _c('FarmFilter',{key:'farmFilter' + index,attrs:{"name":_vm.seriesFinancial[index].name,"color":_vm.seriesFinancial[index].color},on:{"toggle":(e) =>
                    _vm.toggleFarm(e, 'financial', _vm.seriesFinancial[index].name)}})}),_c('FarmFilter',{attrs:{"name":_vm.seriesFinancial[_vm.seriesFinancial.length - 1].name,"color":_vm.seriesFinancial[_vm.seriesFinancial.length - 1].color},on:{"toggle":(e) => _vm.toggleFarm(e, 'financial', _vm.farm)}})],2)],1):_vm._e(),_vm._l((_vm.categoriesFinancial),function(category,key){return _c('v-container',{key:'financial' + key,style:({
              width: `${
                _vm.farmsIdWithFinancial.length < 4 ? category.length * 25 : '100'
              }%`,
            }),attrs:{"fluid":""}},[_c('apex-chart',{attrs:{"series":_vm.filteredSeriesFinancial.slice(
                  key *
                    (_vm.farmsIdWithFinancial.length -
                      _vm.removedSeriesFinancial.length +
                      1),
                  key *
                    (_vm.farmsIdWithFinancial.length -
                      _vm.removedSeriesFinancial.length +
                      1) +
                    _vm.farmsIdWithFinancial.length -
                    _vm.removedSeriesFinancial.length +
                    1
                ),"categories":category,"min":0,"max":10,"tickAmount":5}})],1)})],2),(Object.keys(_vm.rankingPerformanceAnalysis).length > 0)?_c('v-container',{staticClass:"my-2 custom-container"},[_c('performance-spectre',{attrs:{"filteredFarm":_vm.selectedFarm,"farms":_vm.farms,"harvests":this.harvests.map((item) => item.harvest),"initial-data":_vm.rankingPerformanceAnalysis,"filters":_vm.filters}})],1):_vm._e()],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }