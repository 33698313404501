<template>
  <div style="width: 100%">
    <vue-apex-chart
      align="center"
      ref="customChart"
      type="radar"
      height="480"
      :options="chartOptions"
      :series="formatedSeries" />
  </div>
</template>

<script>
import numberPatternMixin from "@/support/utils/numberPattern.mixin";

export default {
  components: {},
  name: "SpectreChart",
  mixins: [numberPatternMixin],

  props: {
    categories: [],
    series: [],
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          type: "radar",
          toolbar: {
            show: false,
          },
        },

        stroke: {
          width: 2,
        },

        fill: {
          opacity: 0.5,
        },

        colors: [
          "#FE5900",
          "#ffd570",
          "#0F3F62",
          "#59BCFA",
          "#99E15D",
          "#9C27B0",
          "#628298",
        ],

        markers: {
          size: 3,
          strokeWidth: 0,
        },

        legend: {
          position: "top",
          markers: {
            width: 20,
            height: 9,
            radius: 0,
          },
        },

        dataLabels: {
          formatter: function (val) {
            return this.decimalPattern(val);
          },
        },

        xaxis: {
          categories: this.categories,
        },
      };
    },
    formatedSeries() {
      return this.series.map((serie) => {
        if (serie.data.length === 0) {
          serie = [0];
        }
        serie.data = serie.data.map((number) => {
          return this.decimalPattern(number);
        });
        return serie;
      });
    },
  },
};
</script>
