<template>
  <v-container class="ma-0 pa-0">
    <v-card class="mb-2">
      <v-card-title class="custom-container">
        <v-container>
          <v-row>
            <v-col cols="12" md="4" :sm="false">
              <custom-card-title align="start" class="grey--text text--darken-2" label="Ranking de Produtividade" />
            </v-col>
            <v-col>
              <v-row class="pa-0 d-flex justify-end">
                <v-col class="px-1 py-1 py-md-2" cols="12" md="5">
                  <simple-select :items="cultures" itemText="name" itemValue="name" v-model="selectedCulture"
                    @input="filterCulture" placeholder="Cultura" height="auto" />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-container class="d-flex justify-center" v-if="loading">
            <v-progress-circular :size="70" :width="7" indeterminate color="primary" />
          </v-container>

          <v-container v-else>
            <v-row class="mb-2">
              <v-col class="d-flex flex-column align-items-center" cols="12" md="4">
                <h3 class="text-center">Ranking Nacional</h3>
                <span class="card-ranking">
                  <p class="text-ranking text-center">
                    {{ filteredNationalRanking.position }}º
                  </p>

                  <custom-label v-if="filteredNationalRanking.pool" :label="`/${filteredNationalRanking.pool}`"
                    class="pool-size" />
                </span>
              </v-col>
              <v-col class="d-flex flex-column align-items-center" cols="12" md="4">
                <h3 class="text-center">Ranking Regional</h3>
                <span class="card-ranking">
                  <p class="text-ranking text-center">
                    {{ filteredRegionalRanking.position }}º
                  </p>

                  <custom-label v-if="filteredRegionalRanking.pool" :label="`/${filteredRegionalRanking.pool}`"
                    class="pool-size" />
                </span>
              </v-col>
              <v-col class="d-flex flex-column align-items-center" cols="12" md="4">
                <h3 class="text-center">Ranking Estadual</h3>
                <span class="card-ranking">
                  <p class="text-ranking text-center">
                    {{ filteredStateRanking.position }}º
                  </p>

                  <custom-label v-if="filteredStateRanking.pool" :label="`/${filteredStateRanking.pool}`"
                    class="pool-size" />
                </span>
              </v-col>
            </v-row>
            <v-row class="py-4">
              <v-col class="d-flex justify-center" :class="!Number(filteredNationalRanking.avg) ? 'grey-scale' : ''">
                <general-speedometer :value="Number(filteredNationalRanking.avg) || 0" :max="filteredNationalRanking.max"
                  :min="filteredNationalRanking.min" :small="$vuetify.breakpoint.smAndDown"
                  custom-text="Média de Produtividade" :changeMinMax="true" number-enfasis />
              </v-col>
              <v-col class="d-flex justify-center" :class="!Number(filteredRegionalRanking.avg) ? 'grey-scale' : ''">
                <general-speedometer :value="Number(filteredRegionalRanking.avg) || 0" :max="filteredRegionalRanking.max"
                  :min="filteredRegionalRanking.min" :small="$vuetify.breakpoint.smAndDown"
                  custom-text="Média de Produtividade" :changeMinMax="true" number-enfasis />
              </v-col>
              <v-col class="d-flex justify-center" :class="!Number(filteredStateRanking.avg) ? 'grey-scale' : ''">
                <general-speedometer :value="Number(filteredStateRanking.avg) || 0" :max="filteredStateRanking.max"
                  :min="filteredStateRanking.min" :small="$vuetify.breakpoint.smAndDown"
                  custom-text="Média de Produtividade" :changeMinMax="true" number-enfasis />
              </v-col>
            </v-row>
          </v-container>
        </v-container>
      </v-card-title>
    </v-card>
  </v-container>
</template>

<script>
import CustomCardTitle from "@/components/texts/CustomCardTitle";
import SimpleSelect from "@/components/inputs/SimpleSelect";
import GeneralSpeedometer from "@/components/graphs/speedometers/GeneralSpeedometer.vue";
import CustomLabel from "@/components/texts/CustomLabel";

import PlotRecord from "@/domain/records/plot-records/plot-records";

export default {
  components: {
    CustomCardTitle,
    SimpleSelect,
    GeneralSpeedometer,
    CustomLabel,
  },
  props: {
    cultures: {
      type: Array,
      required: true,
    },
    farm: {
      type: Number,
      required: true,
    },
    harvest: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      //Flags
      loading: true,

      //Domain
      PlotRecordService: null,

      //Filters
      selectedCulture: null,

      //Data
      coverages: [
        { text: "Ranking Nacional", value: "national" },
        { text: "Ranking Regional", value: "regional" },
        { text: "Ranking Estadual", value: "state" },
      ],

      //Fetched Data
      nationalRanking: null,
      regionalRanking: null,
      StateRanking: null,

      //Filtered Data
      filteredNationalRanking: null,
      filteredRegionalRanking: null,
      filteredStateRanking: null,
    };
  },
  watch: {
    farm(newValue) {
      if (this.harvest && newValue) this.fetchData();
    },
    harvest(newValue) {
      if (this.farm && newValue) this.fetchData();
    },
  },

  methods: {
    async initialize() {
      this.PlotRecordService = PlotRecord;

      await this.fetchData();
    },

    async fetchData() {
      this.loading = true;
      try {
        await Promise.allSettled([
          this.loadNationalRanking(),
          this.loadRegionalRanking(),
          this.loadStateRanking(),
        ]);
        this.selectedCulture = this.cultures[0].name;
        this.filterCulture();

      } catch (err) {
        console.error(err)
      } finally {
        this.loading = false
      }
    },

    async loadNationalRanking() {
      this.nationalRanking = null;
      try {
        const { data } = await this.PlotRecordService.getFarmRankingNational(
          this.farm,
          this.harvest
        );

        this.nationalRanking = data.data;
      } catch (response) {
        console.error(response);
      }
    },
    async loadRegionalRanking() {
      this.regionalRanking = null;
      try {
        const { data } = await this.PlotRecordService.getFarmRankingRegional(
          this.farm,
          this.harvest
        );

        this.regionalRanking = data.data;
      } catch (response) {
        console.error(response);
      }
    },
    async loadStateRanking() {
      this.stateRanking = null;
      try {
        const { data } = await this.PlotRecordService.getFarmRankingState(
          this.farm,
          this.harvest
        );

        this.stateRanking = data.data;
      } catch (response) {
        console.error(response);
      }
    },

    filterCulture() {
      this.loading = true;
      const culture = this.selectedCulture;

      const defaultFiltered = {
        min: 0,
        max: 100,
        avg: "--",
        position: "--",
        pool: "--",
        default: true,
      };

      try {
        this.filteredNationalRanking =
          this.nationalRanking[culture] || defaultFiltered;
        this.filteredRegionalRanking =
          this.regionalRanking[culture] || defaultFiltered;
        this.filteredStateRanking =
          this.stateRanking[culture] || defaultFiltered;
      } finally {
        this.loading = false;
      }
    },
  },

  mounted() {
    this.initialize();
  },
};
</script>

<style lang="scss" scoped>
.card-ranking {
  position: relative;
  display: block;
  margin: auto;
  margin-top: 0.5rem;
  width: 135px;
  height: 58px;
  background: #ededed 0% 0% no-repeat padding-box;
  border: 2px solid #cfa545;
  border-radius: 14px;
  align-self: center;
}

.text-ranking {
  padding-top: 0.8rem;
  font: normal normal bold 35px/30px PT Sans;
  letter-spacing: 0.28px;
  color: #212020;
}

.pool-size {
  position: absolute;
  top: 20px;
  right: -35px;
  font-size: 18px;
  font: normal normal bold 16px/30px PT Sans;
}

.grey-scale {
  filter: grayscale(93%);
}

.custom-container {
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  padding: 1.5rem 2rem 0.75rem 2rem;
}
</style>
