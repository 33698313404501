import http from "@/services/http.service";

const apiUrl = "/get-ranking-data";

class RankingPerformanceAnalysisService {
  static getRankingData(payload, filters) {
    return http.post(`${apiUrl}`, payload, { params: { ...filters } });
  }
}

export default RankingPerformanceAnalysisService;
