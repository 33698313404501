import RankingPerformanceAnalysisService from "./ranking-performance-analysis.service";

class RankingPerformanceAnalysis {
  constructor(payload = {}) {
      Object.assign(this, {
          ...RankingPerformanceAnalysis.build(payload),
      });
  }

  static build(
    {
      farmRankingNational   = '',
      farmRankingManagement = '',
      farmRankingFertility  = '',
      farmRankingFinancial  = '',
      managementIndexes     = [],
      fertilityIndexes      = [],
      financialIndexes      = []
    }
  ) {
    return {
      farmRankingNational,
      farmRankingManagement,
      farmRankingFertility,
      farmRankingFinancial,
      managementIndexes,
      fertilityIndexes,
      financialIndexes 
    }
  }

  static newData(payload) {
      return new RankingPerformanceAnalysis(payload);
  }

  static getRankingData(payload, filters) {
    return new Promise((resolve, reject) => {
      RankingPerformanceAnalysisService
      .getRankingData(payload, filters)
      .then(
        success => {
          const response = success;
          response.data = this.newData(response.data);
          resolve(response);
        },

        error => {
            reject(error);
        }
      );
    });
  }
}

export default RankingPerformanceAnalysis;
