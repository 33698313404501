import http from "@/services/http.service";

class RankingsService {
  static getNationalRanking(harvest,filters) {
    return http.get(`national-ranking/harvests/${harvest}`, {params:{...filters}});
  }

  static getRegionalRanking(as, harvest, filters) {
    return http.get(`regional-ranking/as/${as}/harvests/${harvest}`, {params:{...filters}});
  }

  static getStateRanking(stateId, harvest, filters) {
    return http.get(`state-ranking/state/${stateId}/harvests/${harvest}`, {params:{...filters}});
  }

  static getNationalProducerRanking(farmId, harvest) {
    return http.get(`producers/national-ranking/farms/${farmId}/harvests/${harvest}`);
  }

  static getRegionalProducerRanking(farmId, harvest) {
    return http.get(`producers/regional-ranking/farms/${farmId}/harvests/${harvest}`);
  }

  static getStateProducerRanking(stateId, farmId, harvest) {
    return http.get(`producers/state-ranking/state/${stateId}/farms/${farmId}/harvests/${harvest}`);
  }
}

export default RankingsService;
