<template>
  <div>
    <custom-h1
      class="grey--text text--darken-2 pt-5"
      label="Análise de Desempenho" />

    <search-bar
      :hasTextInput="false"
      :hasClearFilters="false"
      :hasActionButton="true"
      :noSecondaryInput="false"
      :noTertiaryInput="false"
      actionButtonLabel="Buscar"
      @externalAction="searchFarm()">
      <simple-select
        v-if="authoritySlug !== 'producer'"
        :items="producers"
        v-model="selectedProducer"
        class="mr-4"
        placeholder="Produtor"
        itemText="user.name"
        itemValue="producer_id"
        @input="loadFarms"
        returnObject
        height="auto"
        :menuProps="{ bottom: true, offsetY: true, closeOnClick: true }" />

      <template slot="tertiary-input">
        <simple-select
          :items="farms"
          v-model="selectedFarm"
          class="mr-4"
          placeholder="Fazenda"
          itemText="name"
          :loading="loadingFarms"
          itemValue="id"
          returnObject
          height="auto"
          :menuProps="{ bottom: true, offsetY: true, closeOnClick: true }" />
      </template>
    </search-bar>

    <div
      v-if="
        Object.keys(rankingPerformanceAnalysis).length > 0 &&
        farmsSelected.length > 0
      ">
      <v-card class="mb-12 rounded-xl">
        <v-card-title class="yellow-border">
          <!-- Registro de Fertilidade Geral -->
          <v-container
            class="my-2 custom-container"
            :key="componentKeyFertility"
            v-if="selectedFertilityType === 'FG'">
            <v-row>
              <v-col cols="12" md="4" class="d-flex justify-start">
                <custom-card-title
                  style="padding-left: 1.7rem !important"
                  class="grey--text text--darken-2"
                  label="Registro de Fertilidade" />
              </v-col>
              <v-col md="8" class="d-flex py-0 pb-4 justify-end">
                <v-row class="mt-2 px-2">
                  <v-col md="5" cols="12" class="pa-0">
                    <simple-select
                      :items="fertilityTypes"
                      v-model="selectedFertilityType"
                      itemText="text"
                      itemValue="value"
                      height="auto"
                      :menuProps="{
                        bottom: true,
                        offsetY: true,
                        closeOnClick: true,
                      }"
                      class="mr-2 mb-2" />
                  </v-col>
                  <v-col md="4" cols="12" class="pa-0">
                    <simple-select
                      :items="filter"
                      v-model="selectedFilterFertility"
                      itemText="text"
                      itemValue="text"
                      height="auto"
                      :menuProps="{
                        bottom: true,
                        offsetY: true,
                        closeOnClick: true,
                      }"
                      @custom-change="changeFertility()"
                      class="mr-2 mb-2" />
                  </v-col>
                  <v-col md="3" cols="12" class="pa-0">
                    <simple-select
                      :items="this.harvests.map((item) => item.harvest)"
                      v-model="harvestFertility"
                      itemText="harvest"
                      itemValue="harvest"
                      placeholder="Safra"
                      height="auto"
                      @custom-change="changeFertility()"
                      :menuProps="{
                        bottom: true,
                        offsetY: true,
                        closeOnClick: true,
                      }"
                      class="mr-2 mb-2" />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-container
              v-if="farmsIdWithFertility.length > 4 && seriesFertility">
              <h4>Filtrar Fazendas</h4>
              <v-container class="filters d-flex justify-center flex-wrap">
                <FarmFilter
                  v-for="(farm, index) in farmsIdWithFertility"
                  :key="'farmFilter' + index"
                  :name="seriesFertility[index].name"
                  :color="seriesFertility[index].color"
                  @toggle="
                    (e) =>
                      toggleFarm(e, 'fertility', seriesFertility[index].name)
                  ">
                </FarmFilter>
                <FarmFilter
                  :name="seriesFertility[seriesFertility.length - 1].name"
                  :color="seriesFertility[seriesFertility.length - 1].color"
                  @toggle="(e) => toggleFarm(e, 'fertility', farm)">
                </FarmFilter>
              </v-container>
            </v-container>

            <v-container
              v-for="(category, key) in categoriesFertility"
              :key="'fertility' + key"
              fluid
              :style="{
                width: `${
                  farmsIdWithFertility.length < 4 ? category.length * 25 : '100'
                }%`,
              }">
              <apex-chart
                :series="
                  filteredSeriesFertility.slice(
                    key *
                      (farmsIdWithFertility.length -
                        removedSeriesFertility.length +
                        1),
                    key *
                      (farmsIdWithFertility.length -
                        removedSeriesFertility.length +
                        1) +
                      (farmsIdWithFertility.length -
                        removedSeriesFertility.length) +
                      1
                  )
                "
                :categories="category"
                :min="0"
                :max="10"
                :tickAmount="5" />
            </v-container>
          </v-container>

          <!-- Registro de Fertilidade por Talhão -->
          <v-container
            class="my-2 custom-container"
            :key="componentKeyFertilityField"
            v-if="selectedFertilityType === 'FT'">
            <v-row>
              <v-col cols="12" md="5" class="d-flex justify-start">
                <custom-card-title
                  style="padding-left: 1.7rem !important"
                  class="grey--text text--darken-2"
                  label="Registro de Fertilidade por Talhão" />
              </v-col>
              <v-col md="7" class="d-flex py-0 pb-4 justify-end">
                <v-row class="mt-2 px-2">
                  <v-col md="7" cols="12" class="pa-0">
                    <simple-select
                      :items="fertilityTypes"
                      v-model="selectedFertilityType"
                      itemText="text"
                      itemValue="value"
                      height="auto"
                      :menuProps="{
                        bottom: true,
                        offsetY: true,
                        closeOnClick: true,
                      }"
                      class="mr-2 mb-2" />
                  </v-col>
                  <v-col md="5" cols="12" class="pa-0">
                    <simple-select
                      :items="this.harvests.map((item) => item.harvest)"
                      v-model="selectedFertilityHarvestByField"
                      itemText="harvest"
                      itemValue="harvest"
                      placeholder="Safra"
                      height="auto"
                      :menuProps="{
                        bottom: true,
                        offsetY: true,
                        closeOnClick: true,
                      }"
                      @custom-change="changeFertilityByField()"
                      class="mr-2 mb-2" />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="mt-2 px-2">
              <v-col md="5" offset-md="4" cols="12" class="pa-0">
                <simple-select
                  :items="farmsSelected"
                  v-model="selectedFertilityFarmByField"
                  itemText="name"
                  itemValue="id"
                  placeholder="Selecione uma fazenda"
                  height="auto"
                  :menuProps="{
                    bottom: true,
                    offsetY: true,
                    closeOnClick: true,
                  }"
                  :disabled="!selectedFertilityHarvestByField"
                  @custom-change="getFertilityByField()"
                  class="mr-2 mb-2" />
              </v-col>
              <v-col md="3" cols="12" class="pa-0">
                <simple-select
                  :items="fertilityIndexes.map((item) => item.index_name)"
                  v-model="selectedFertilityIndexByField"
                  placeholder="Selecione um índice"
                  height="auto"
                  :menuProps="{
                    bottom: true,
                    offsetY: true,
                    closeOnClick: true,
                  }"
                  :disabled="!selectedFertilityHarvestByField"
                  @custom-change="getFertilityByField()"
                  class="mr-2 mb-2" />
              </v-col>
            </v-row>

            <v-container
              v-if="seriesFertilityField.length > 0"
              fluid
              :style="{
                width: `${
                  farmsIdWithFertility.length < 4 ? category.length * 25 : '100'
                }%`,
              }">
              <apex-chart
                v-for="(serie, index) of seriesFertilityField"
                :key="'fertilityField' + index"
                :series="serie"
                :categories="categoriesFertilityField"
                :min="0"
                :max="10"
                :tickAmount="5" />
            </v-container>
          </v-container>

          <!-- Registro de Manejo -->
          <v-container class="my-2 custom-container" :key="componentKey">
            <v-row>
              <v-col cols="12" md="4" class="d-flex justify-start">
                <custom-card-title
                  style="padding-left: 1.7rem !important"
                  class="grey--text text--darken-2"
                  label="Registro de Manejo" />
              </v-col>

              <v-col md="8" class="d-flex py-0 pb-4 justify-end">
                <v-row class="mt-2 px-2">
                  <v-col md="4" cols="12" class="pa-0">
                    <simple-select
                      :items="filter"
                      v-model="selectedFilterManagement"
                      class="mr-2 mb-2"
                      itemText="text"
                      itemValue="text"
                      height="auto"
                      :menuProps="{
                        bottom: true,
                        offsetY: true,
                        closeOnClick: true,
                      }"
                      @custom-change="changeManagement()" />
                  </v-col>
                  <v-col md="5" cols="12" class="pa-0">
                    <simple-select
                      :items="this.managementPhases"
                      v-model="selectedManagementPhase"
                      class="mr-2 mb-2"
                      itemText="text"
                      itemValue="value"
                      height="auto"
                      @custom-change="changeManagement()"
                      :menuProps="{
                        bottom: true,
                        offsetY: true,
                        closeOnClick: true,
                      }" />
                  </v-col>
                  <v-col md="3" cols="12" class="pa-0">
                    <simple-select
                      :items="this.harvests.map((item) => item.harvest)"
                      v-model="harvestManagement"
                      class="mr-2 mb-2"
                      itemText="harvest"
                      itemValue="harvest"
                      placeholder="Safra"
                      height="auto"
                      @custom-change="changeManagement()"
                      :menuProps="{
                        bottom: true,
                        offsetY: true,
                        closeOnClick: true,
                      }" />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-container
              v-if="
                farmsIdWithManagementPhase.length > 4 && seriesManagementPhase
              ">
              <h4>Filtrar Fazendas</h4>
              <v-container class="filters d-flex justify-center flex-wrap">
                <FarmFilter
                  v-for="(farm, index) in farmsIdWithFertility"
                  :key="'farmFilter' + index"
                  :name="seriesManagementPhase[index].name"
                  :color="seriesManagementPhase[index].color"
                  @toggle="
                    (e) =>
                      toggleFarm(
                        e,
                        'management',
                        seriesManagementPhase[index].name
                      )
                  ">
                </FarmFilter>
                <FarmFilter
                  :name="
                    seriesManagementPhase[seriesManagementPhase.length - 1].name
                  "
                  :color="
                    seriesManagementPhase[seriesManagementPhase.length - 1]
                      .color
                  "
                  @toggle="(e) => toggleFarm(e, 'management', farm)">
                </FarmFilter>
              </v-container>
            </v-container>

            <v-container
              fluid
              v-for="(category, key) in categoriesManagementPhase"
              :key="'management' + key"
              :style="{
                width: `${
                  farmsIdWithManagementPhase.length < 4
                    ? category.length * 25
                    : '100'
                }%`,
              }">
              <apex-chart
                :series="
                  filteredSeriesManagement.slice(
                    key *
                      (farmsIdWithManagementPhase.length -
                        removedSeriesManagement.length +
                        1),
                    key *
                      (farmsIdWithManagementPhase.length -
                        removedSeriesManagement.length +
                        1) +
                      farmsIdWithManagementPhase.length -
                      removedSeriesManagement.length +
                      1
                  )
                "
                :categories="category"
                :min="0"
                :max="10"
                :tickAmount="5" />
            </v-container>
          </v-container>

          <!-- Registro Financeiro -->
          <v-container
            class="my-2 custom-container"
            :key="componentKeyFinancial">
            <v-row>
              <v-col cols="12" md="5" class="d-flex justify-start">
                <custom-card-title
                  style="padding-left: 1.7rem !important"
                  class="grey--text text--darken-2"
                  label="Registro de Financeiro" />
              </v-col>
              <v-col md="7" class="d-flex py-0 pb-4 justify-end">
                <v-row class="mt-2 px-2">
                  <v-col md="7" cols="12" class="pa-0">
                    <simple-select
                      :items="filter"
                      v-model="selectedFilterFinancial"
                      itemText="text"
                      itemValue="text"
                      height="auto"
                      :menuProps="{
                        bottom: true,
                        offsetY: true,
                        closeOnClick: true,
                      }"
                      @custom-change="changeFinancial()"
                      class="mr-2 mb-2" />
                  </v-col>
                  <v-col md="5" cols="12" class="pa-0">
                    <simple-select
                      :items="this.harvests.map((item) => item.harvest)"
                      v-model="harvestFinancial"
                      itemText="harvest"
                      itemValue="harvest"
                      placeholder="Safra"
                      height="auto"
                      @custom-change="changeFinancial()"
                      :menuProps="{
                        bottom: true,
                        offsetY: true,
                        closeOnClick: true,
                      }"
                      class="mr-2 mb-2" />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-container
              v-if="farmsIdWithFinancial.length > 4 && seriesFinancial">
              <h4>Filtrar Fazendas</h4>
              <v-container class="filters d-flex justify-center flex-wrap">
                <FarmFilter
                  v-for="(farm, index) in farmsIdWithFinancial"
                  :key="'farmFilter' + index"
                  :name="seriesFinancial[index].name"
                  :color="seriesFinancial[index].color"
                  @toggle="
                    (e) =>
                      toggleFarm(e, 'financial', seriesFinancial[index].name)
                  ">
                </FarmFilter>
                <FarmFilter
                  :name="seriesFinancial[seriesFinancial.length - 1].name"
                  :color="seriesFinancial[seriesFinancial.length - 1].color"
                  @toggle="(e) => toggleFarm(e, 'financial', farm)">
                </FarmFilter>
              </v-container>
            </v-container>

            <v-container
              fluid
              v-for="(category, key) in categoriesFinancial"
              :key="'financial' + key"
              :style="{
                width: `${
                  farmsIdWithFinancial.length < 4 ? category.length * 25 : '100'
                }%`,
              }">
              <apex-chart
                :series="
                  filteredSeriesFinancial.slice(
                    key *
                      (farmsIdWithFinancial.length -
                        removedSeriesFinancial.length +
                        1),
                    key *
                      (farmsIdWithFinancial.length -
                        removedSeriesFinancial.length +
                        1) +
                      farmsIdWithFinancial.length -
                      removedSeriesFinancial.length +
                      1
                  )
                "
                :categories="category"
                :min="0"
                :max="10"
                :tickAmount="5" />
            </v-container>
          </v-container>
          <!-- Gráfico de Espectro -->
          <v-container
            v-if="Object.keys(rankingPerformanceAnalysis).length > 0"
            class="my-2 custom-container">
            <performance-spectre
              :filteredFarm="selectedFarm"
              :farms="farms"
              :harvests="this.harvests.map((item) => item.harvest)"
              :initial-data="rankingPerformanceAnalysis"
              :filters="filters" />
          </v-container>
        </v-card-title>
      </v-card>
    </div>
  </div>
</template>

<script>
import CustomH1 from "@/components/texts/CustomH1";
import CustomCardTitle from "@/components/texts/CustomCardTitle";
import SearchBar from "@/components/layouts/SearchBar";
import SimpleSelect from "@/components/inputs/SimpleSelect";

import ApexChart from "@/components/graphs/apex/ApexChart";

import rankingPerformanceAnalysisService from "@/domain/ranking-performance-analysis/ranking-performance-analysis";
import farmsService from "@/domain/farms/farms";

import PerformanceSpectre from "@/views/pages/graphs/PerformanceSpectre.vue";
import { randomColor } from "../../../support/utils/randomColor";
import FarmFilter from "../../../components/buttons/FarmFilter.vue";

export default {
  name: "PerformanceAnalysis",
  components: {
    CustomH1,
    CustomCardTitle,
    SearchBar,
    SimpleSelect,
    ApexChart,
    PerformanceSpectre,
    FarmFilter,
  },
  props: {
    farm: {
      type: Object,
      required: true,
    },
    filteredFarms: {
      type: Array,
      required: true,
    },
    harvest: {
      type: Number,
      required: true,
    },
    producer: {
      type: Object,
      required: true,
    },
    producers: {
      type: Array,
      required: true,
    },
    authoritySlug: {
      type: String,
      required: true,
    },
    harvests: {
      type: Array,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    selectedFarm: {},
    selectedProducer: {},
    rankingPerformanceAnalysis: {},

    loading: false,

    farms: [],
    loadingFarms: false,
    farmsSelected: [],

    farmsIdWithFertility: [],
    farmsIdWithFinancial: [],
    farmsIdWithManagementPhase: [],

    seriesFertility: [],
    filteredSeriesFertility: [],
    seriesFertilityField: [],

    seriesFinancial: [],
    filteredSeriesFinancial: [],

    seriesManagementPhase: [],
    filteredSeriesManagement: [],

    categoriesFertility: [],
    categoriesFertilityField: [],
    categoriesFinancial: [],
    categoriesManagementPhase: [],

    fertilityRecord: [],
    fertilityIndexes: [],

    selectedManagementPhase: 3,
    managementPhases: [
      { value: 3, text: "Pós-Plantio" },
      { value: 4, text: "Pós-Florescimento" },
      { value: 5, text: "Colheita" },
    ],

    selectedFertilityType: "FG",

    fertilityTypes: [
      { value: "FG", text: "Fertilidade Geral" },
      { value: "FT", text: "Fertilidade/Talhão" },
    ],

    selectedFilterManagement: "Nacional",
    selectedFilterFertility: "Nacional",
    selectedFilterFinancial: "Nacional",

    filter: [{ text: "Nacional" }, { text: "Regional" }, { text: "Estadual" }],

    currentHarvest: null,
    harvestManagement: null,
    harvestFertility: null,
    harvestFinancial: null,
    selectedFertilityHarvestByField: null,
    selectedFertilityFarmByField: null,
    selectedFertilityIndexByField: null,

    selectHarvestManagement: false,
    selectHarvestFertility: false,
    selectHarvestFinancial: false,
    selectFertilityField: false,

    componentKey: 100,
    componentKeyFertility: 200,
    componentKeyFertilityField: 300,
    componentKeyFinancial: 4000,

    removedSeriesFertility: [],
    removedSeriesFinancial: [],
    removedSeriesManagement: [],

    beforeMount: false,
  }),

  methods: {
    async loadFarms() {
      this.farms = [];
      this.loadingFarms = true;
      this.searched = false;
      this.selectedFarm = {};
      try {
        const producerId =
          this.producer_id || this.selectedProducer.producer_id;
        const { data } = await farmsService.listByProducer(producerId);
        this.farms = data;
      } catch (err) {
        console.error(err);
      } finally {
        this.loadingFarms = false;
      }
    },
    async getPerformanceAnalysis(harvest, coverage = "Nacional", phase_id = 3) {
      const loader = this.loading ? false : this.$loading.show();
      this.loading = !!loader;

      this.farmsSelected = [];
      this.rankingPerformanceAnalysis = {};

      /**
       * Fazendas que foram selecionadas
       */
      if (this.selectedFarm.id === "T") {
        this.farms.map((item) => {
          item.id !== "T" && this.farmsSelected.push(item);
        });
      } else {
        this.farmsSelected.push(this.selectedFarm);
      }

      if (this.farmsSelected.length === 0) {
        return;
      }

      const data = {
        farm_id: this.farmsSelected[0].id,
        harvest: harvest,
        phase_id: phase_id,
        coverage: coverage,
      };

      await rankingPerformanceAnalysisService
        .getRankingData(data, this.filters)
        .then((result) => {
          this.rankingPerformanceAnalysis = result.data;
        });

      this.buildDataToChart();

      if (loader) {
        loader.hide();
        this.loading = false;
      }
    },

    searchFarm() {
      if (!this.selectedFarm || Object.keys(this.selectedFarm).length === 0) {
        this.$toasted.show("É necessário selecionar uma fazenda.", {
          type: "error",
          position: "top-right",
        });
      } else {
        this.seriesFertility = [];
        this.categoriesFertility = [];
        this.farmsIdWithFertility = [];

        this.seriesFinancial = [];
        this.categoriesFinancial = [];
        this.farmsIdWithFinancial = [];

        this.seriesManagementPhase = [];
        this.categoriesManagementPhase = [];
        this.farmsIdWithManagementPhase = [];

        this.farmsSelected = [];
        this.fertilityIndexes = [];
        this.seriesFertilityField = [];
        this.categoriesFertilityField = [];

        this.selectedManagementPhase = 3;
        this.selectedFertilityType = "FG";
        this.selectedFilterManagement = "Nacional";
        this.selectedFilterFertility = "Nacional";
        this.selectedFilterFinancial = "Nacional";

        this.selectedFertilityHarvestByField = null;
        this.selectedFertilityFarmByField = null;
        this.selectedFertilityIndexByField = null;

        this.componentKey++;
        this.componentKeyFertility++;
        this.componentKeyFinancial++;

        this.getPerformanceAnalysis(this.harvest);
      }
    },

    buildDataToChart() {
      this.selectFertilityField && this.getFertilityByField();
      this.selectHarvestManagement && this.getManagement();
      this.selectHarvestFertility && this.getFertility();
      this.selectHarvestFinancial && this.getFinancial();
    },

    getManagement() {
      const managementIndexes =
        this.rankingPerformanceAnalysis.managementIndexes.original;

      [
        this.seriesManagementPhase,
        this.categoriesManagementPhase,
        this.farmsIdWithManagementPhase,
      ] = this.getRecordIndexes(
        managementIndexes,
        this.selectedFilterManagement
      );

      this.filteredSeriesManagement = this.seriesManagementPhase;

      this.selectHarvestManagement = false;
    },

    getFertility() {
      const fertilityIndexes =
        this.rankingPerformanceAnalysis.fertilityIndexes.original;

      [
        this.seriesFertility,
        this.categoriesFertility,
        this.farmsIdWithFertility,
      ] = this.getRecordIndexes(
        fertilityIndexes,
        this.selectedFilterFertility,
        true
      );

      this.filteredSeriesFertility = this.seriesFertility;

      this.selectHarvestFertility = false;
    },

    getFinancial() {
      const financialIndexes =
        this.rankingPerformanceAnalysis.financialIndexes.original;

      [
        this.seriesFinancial,
        this.categoriesFinancial,
        this.farmsIdWithFinancial,
      ] = this.getRecordIndexes(financialIndexes, this.selectedFilterFinancial);

      this.filteredSeriesFinancial = this.seriesFinancial;

      this.selectHarvestFinancial = false;
    },

    getRecordIndexes(recordIndexes, coverageName, fertility = false) {
      let series = [];
      let performance = [];
      let categories = [];
      let keys = [];
      let farmsIdWithRecords = [];
      let records = [];
      let colors = [
        "#FE5900",
        "#ffd570",
        "#0F3F62",
        "#59BCFA",
        "#99E15D",
        "#9C27B0",
        "#628298",
      ];
      let count = 0;
      let colorPosition = -1;
      const splitAmount = this.farmsSelected.length > 4 ? 1 : 4;

      this.farmsSelected.forEach((farm) => {
        records = [];

        recordIndexes.map((record) => {
          let filteredRecord = record.farms.filter(
            (item) => item.farm_id === farm.id
          );

          if (filteredRecord.length === 0) return;

          if (fertility) this.fertilityIndexes.push(record);

          filteredRecord[0]["name"] = record["index_name"];
          filteredRecord[0]["avg_total"] = record["avg_total"];
          records.push(filteredRecord[0]);
        });

        if (records.length === 0) return;

        count = 0;
        colorPosition++;
        if (colorPosition >= colors.length) colorPosition = 0;

        /**
         * Divide o array de índices de 4 em 4 posições
         */
        records = this.splitArray(records, splitAmount);

        records.map((record) => {
          const farmName = record[0].farm_name;
          const indexesNames = record.map(({ name }) => name);
          let grades = record.map(({ avg }) => avg);

          grades = grades.map((grade) => grade || 0);

          farmsIdWithRecords.push(farm.id);

          /**
           * Monta o objeto com o nome da fazenda e as notas dos
           * índices
           */

          performance.push({
            [count]: {
              name: farmName,
              data: grades,
              color: colors[colorPosition],
            },
          });

          indexesNames.map((name) => categories.push(name));
          keys.push(count);
          ++count;
        });
      });

      /**
       * Cobertura (Nacional ou Regional ou Estadual)
       */
      let splitRecords = [];

      if (this.selectedFarm.id === "T") {
        splitRecords = this.splitArray(recordIndexes, splitAmount);
      } else {
        splitRecords = this.splitArray(records, splitAmount)[0];
      }

      count = 0;

      if (splitRecords) {
        splitRecords.map((item) => {
          const avg = item.map(({ avg_total }) => avg_total);

          /**
           * Monta o objeto com o nome da cobertura e as médias dos
           * índices
           */
          performance.push({
            [count]: {
              name: coverageName,
              data: avg,
              color: "#ffd570",
            },
          });
          keys.push(count);
          ++count;
        });
      }

      /**
       * Divide o array categorias de 4 em 4
       */
      categories = [...new Set(categories)];
      const newCategories = this.splitArray(categories, splitAmount);

      /**
       * Monta a forma como o Apex Chart consumirá os dados para
       * convertê-los em gráficos
       */
      keys = [...new Set(keys)];
      keys.map((key) => {
        performance.map((item) => {
          if (item[key]) series.push(item[key]);
        });
      });

      farmsIdWithRecords = [...new Set(farmsIdWithRecords)];

      this.componentKey++;
      this.componentKeyFertility++;
      this.componentKeyFinancial++;
      return [series, newCategories, farmsIdWithRecords];
    },

    changeManagement() {
      this.selectHarvestManagement = true;
      this.getPerformanceAnalysis(
        this.harvestManagement,
        this.selectedFilterManagement,
        this.selectedManagementPhase
      );
    },

    changeFertility() {
      this.selectHarvestFertility = true;
      this.getPerformanceAnalysis(
        this.harvestFertility,
        this.selectedFilterFertility
      );
      this.selectedFertilityHarvestByField = null;
      this.selectedFertilityFarmByField = null;
      this.selectedFertilityIndexByField = null;
      this.seriesFertilityField = [];
      this.categoriesFertilityField = [];
    },

    changeFinancial() {
      this.selectHarvestFinancial = true;
      this.getPerformanceAnalysis(
        this.harvestFinancial,
        this.selectedFilterFinancial
      );
    },

    changeFertilityByField() {
      this.selectFertilityField = true;
      this.getPerformanceAnalysis(
        this.selectedFertilityHarvestByField,
        this.selectedFilterFertility
      );
    },

    getFertilityByField() {
      this.selectFertilityField = false;

      if (
        !this.selectedFertilityFarmByField ||
        !this.selectedFertilityIndexByField
      )
        return;

      const fertilityIndexes =
        this.rankingPerformanceAnalysis.fertilityIndexes.original;
      const colors = [
        "#FE5900",
        "#ffd570",
        "#0F3F62",
        "#59BCFA",
        "#99E15D",
        "#9C27B0",
        "#628298",
      ];
      let records = [];

      fertilityIndexes.map((record) => {
        let filteredRecord = record.farms.filter(
          (farm) => farm.farm_id === this.selectedFertilityFarmByField
        );

        if (filteredRecord.length === 0) return;

        filteredRecord[0]["name"] = record["index_name"];
        records.push(filteredRecord[0]);
      });

      records = records.filter(
        (item) => item.name === this.selectedFertilityIndexByField
      );

      this.seriesFertilityField = [];
      this.categoriesFertilityField = [];

      if (records.length === 0 || records[0].fields.length === 0) return;

      this.componentKeyFertilityField++;

      let divider = 5;

      for (let i = 0; i < records[0].fields.length / divider; i++) {
        let index = i * divider;
        let dividedRecords = [];

        for (
          let j = 0;
          j < divider && index + j < records[0].fields.length;
          j++
        ) {
          let color =
            index + j < colors.length ? colors[index + j] : randomColor();
          dividedRecords.push({
            name: records[0].fields[index + j].field.name,
            data: [records[0].fields[index + j].grade],
            color: color,
          });
        }
        this.seriesFertilityField.push(dividedRecords);
      }

      this.categoriesFertilityField = [this.selectedFertilityIndexByField];
    },

    splitArray(array, n) {
      let response = [];

      while (array.length) {
        response.push(array.splice(0, n));
      }

      return response;
    },

    async initialize() {
      this.farms = [...this.filteredFarms];
      this.farms.unshift({ id: "T", name: "Todas as fazendas" });

      this.selectHarvestFinancial = true;
      this.selectHarvestManagement = true;
      this.selectHarvestFertility = true;

      this.harvestFinancial = this.harvest;
      this.harvestManagement = this.harvest;
      this.harvestFertility = this.harvest;

      this.selectedProducer = { ...this.producer };
      this.selectedFarm = { ...this.farm };
      this.searchFarm();
    },

    toggleFarm(active, type, farmName) {
      if (type === "fertility") {
        if (active)
          this.removedSeriesFertility.splice(
            this.removedSeriesFertility.indexOf(farmName),
            1
          );
        else this.removedSeriesFertility.push(farmName);

        this.filteredSeriesFertility = this.seriesFertility.filter(
          (serie) => !this.removedSeriesFertility.includes(serie.name)
        );
      }
      if (type === "management") {
        if (active)
          this.removedSeriesManagement.splice(
            this.removedSeriesManagement.indexOf(farmName),
            1
          );
        else this.removedSeriesManagement.push(farmName);

        this.filteredSeriesManagement = this.seriesManagementPhase.filter(
          (serie) => !this.removedSeriesManagement.includes(serie.name)
        );
      }
      if (type === "financial") {
        if (active)
          this.removedSeriesFinancial.splice(
            this.removedSeriesFinancial.indexOf(farmName),
            1
          );
        else this.removedSeriesFinancial.push(farmName);

        this.filteredSeriesFinancial = this.seriesFinancial.filter(
          (serie) => !this.removedSeriesFinancial.includes(serie.name)
        );
      }
    },
  },

  beforeMount() {
    this.beforeMount = true;
    this.initialize();
  },
};
</script>

<style scoped>
::v-deep .custom-tertiary-input {
  width: 225px !important;
}

.filters {
  gap: 10px;
}
</style>
