<template>
  <div>
    <div>
      <custom-h1 label="Índices de Qualidade de Lavoura(IQL)" />
      <search-bar :hasClearFilters="false" :hasActionButton="true" :noSecondaryInput="false" :noTertiaryInput="false"
        :noQuaternaryInput="false" :hasTextInput="false" actionButtonLabel="Buscar" @externalAction="searchFarmData()">
        <simple-select v-if="authority === 'admin' || authority === 'super-admin'" :items="producers"
          v-model="selectedProducer" class="mr-4" placeholder="Produtor" itemText="user.name" itemValue="producer_id"
          @input="loadFarms" returnObject height="auto" />

        <template slot="tertiary-input">
          <simple-select :items="farms" v-model="selectedFarm" class="mr-4" placeholder="Fazenda" itemText="name"
            itemValue="id" returnObject height="auto" @input="searchFarmData()" />
        </template>
        <template slot="quaternary-input">
          <simple-select :items="harvests.map((item) => item.harvest)" v-model="selectedHarvest" class="mr-4" @input="searchFarmData()"
            placeholder="Safra" itemText="harvest" itemValue="harvest" returnObject height="auto" />
        </template>
      </search-bar>
      <div v-if="searched">
        <v-card class="mb-12 rounded-xl">
          <v-card-title class="grey--text text--darken-2 yellow-border">
            <v-row>
              <v-col>
                <h2 class="pa-0 font-weight-bold text-sm-left">{{
                  `${farmDetails.name} - Safra de ${selectedHarvest}`
                }}</h2>

              </v-col>
              <v-col class="d-flex justify-center py-1 py-md-2" cols="6" md="1">
                <text-button color="black" noMargin @callAction="filterModal = true" label="Filtrar" />
              </v-col>
            </v-row>

            <rankings-producer :farm="selectedFarm" :harvest="selectedHarvest" :loading="loading" />

            <v-container class="d-flex flex-column pa-0">
              <v-container class="d-flex flex-row custom-container">
                <v-container class="d-flex flex-column">
                  <v-btn-toggle v-model="selectedIql" mandatory color="primary" class="mb-16">
                    <v-btn class="mx-1" value="generalIql" elevation="2">IQL Geral</v-btn>
                    <v-btn class="mx-1" value="fertilityIql" elevation="2">IQL Fertilidade</v-btn>
                    <v-btn class="mx-1" value="managementIql" elevation="2">IQL Manejo</v-btn>
                    <v-btn class="mx-1" value="financialIql" elevation="2">IQL Financeiro</v-btn>
                  </v-btn-toggle>
                  <v-container>
                    <h3 class="grey--text text--darken-2">IQL Fazenda</h3>
                    <div class="d-flex align-center justify-center flex-grow-1"
                      :class="selectedIqlFarmValue ? '' : 'grey-scale'">
                      <general-speedometer custom-text="IQL Geral" :value="selectedIqlFarmValue || 0" number-enfasis />
                    </div>
                  </v-container>
                </v-container>
                <v-container class="mb-2 mx-1 custom-container">
                  <h3 class="grey--text text--darken-2">Comparativo </h3>
                  <div class="d-flex align-center justify-center flex-column">
                    <general-speedometer class="mx-4 my-2" custom-text="IQL Nacional"
                      :value="farmData.nationalIqlCalculation[selectedIql] || 0" />

                    <general-speedometer class="mx-4 my-2" custom-text="IQL Regional"
                      :value="farmData.regionalIqlCalculation[selectedIql] || 0" />
                  </div>
                </v-container>
              </v-container>
              <v-container class="my-2 custom-container">
                <h3 class="grey--text text--darken-2">IQL Por Registro</h3>
                <v-container>
                  <v-row :key="gaugeComponent" class="grid-gauge">
                    <general-gauge :uniqueId="generateRandomString()" outerText="IQL Fertilidade" :value="farmData.fertilityRecord.recordIql !== null
                      ? parseFloat(farmData.fertilityRecord.recordIql) || 0
                      : 0
                      " />

                    <general-gauge :uniqueId="generateRandomString()" outerText="IQL Manejo" :value="farmData.managementRecord &&
                      farmData.managementRecord.data[2].managementFarmIql !== null
                      ? parseFloat(
                        farmData.managementRecord.data[2].managementFarmIql
                      ) || 0
                      : 0
                      " />

                    <general-gauge :uniqueId="generateRandomString()" outerText="IQL Financeiro" :value="farmData.financialRecord.recordIql !== null
                      ? parseFloat(farmData.financialRecord.recordIql) || 0
                      : 0
                      " />
                  </v-row>
                </v-container>

                <h3 class="grey--text text--darken-2">IQL Fases de Manejo </h3>
                <v-container>
                  <v-row class="grid-gauge">
                    <general-gauge :uniqueId="generateRandomString()" outerText="Pós-Plantio" :value="farmData.managementRecord.data[1].postPlantingIql !== null
                      ? parseFloat(
                        farmData.managementRecord.data[1].postPlantingIql
                      ) || 0
                      : 0
                      " />

                    <general-gauge :uniqueId="generateRandomString()" outerText="Pós-Florescimento" :value="farmData.managementRecord.data[1].postFloweringIql !== null
                      ? parseFloat(
                        farmData.managementRecord.data[1].postFloweringIql
                      ) || 0
                      : 0
                      " />


                    <general-gauge :uniqueId="generateRandomString()" outerText="Colheita" :value="farmData.managementRecord.data[1].harvestIql !== null
                      ? parseFloat(
                        farmData.managementRecord.data[1].harvestIql
                      ) || 0
                      : 0
                      " />
                  </v-row>
                </v-container>
              </v-container>
            </v-container>

            <productivity-graph :farm="selectedFarm.id" :harvest="selectedHarvest" :cultures="cultures" />

          </v-card-title>
        </v-card>
        <performance-analysis v-if="!loading" :loading="loading" :farm="selectedFarm" :harvest="selectedHarvest"
          :producer="selectedProducer" :filteredFarms="farms" :producers="producers" :authoritySlug="authority" :harvests="harvests" :filters="filters"></performance-analysis>
      </div>
    </div>
    <v-dialog v-model="filterModal" scrollable transition="dialog-bottom-transition" max-width="600">
      <v-card>
        <v-toolbar color="primary" dark>
          <v-btn icon dark @click="filterModal = false" class="mx-5">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Filtros</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-12">
          <v-container>
            <span class="font-weight-bold font-18 text">Sementeiro</span>
            <v-checkbox v-model="filters.is_sower" :value="true" label="Sim" />
            <v-checkbox v-model="filters.is_sower" :value="false" label="Não" />
            <span class="font-weight-bold font-18 text">Área</span>
            <v-checkbox v-model="filters.farm_size" value="0 a 500" label="0 - 500ha" />
            <v-checkbox v-model="filters.farm_size" value="500 a 1000" label="500 - 1000ha" />
            <v-checkbox v-model="filters.farm_size" value="1000 a 3000" label="1000 - 3000ha" />
            <v-checkbox v-model="filters.farm_size" value="acima de 3000" label="Acima de 3000" />
            <span class="font-weight-bold font-18 text">Irrigação</span>
            <v-checkbox v-model="filters.irrigation" value="Sem irrigação" label="Sem irrigação" />
            <v-checkbox v-model="filters.irrigation" value="Sim, menos de 50% da área" label="< 50% da área irrigada" />
            <v-checkbox v-model="filters.irrigation" value="Sim, mais de 50% da área" label="> 50% da área irrigada" />
            <span class="font-weight-bold font-18 text">Tipo de área</span>
            <v-checkbox v-model="filters.area_type" value="1" label="Própria" />
            <v-checkbox v-model="filters.area_type" value="2" label="Arrendada" />
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="saveFilters">Salvar</v-btn>
          <v-btn color="error" text @click="filterModal = false">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CustomH1 from "@/components/texts/CustomH1";
import SearchBar from "@/components/layouts/SearchBar";
import SimpleSelect from "@/components/inputs/SimpleSelect";
import TextButton from "@/components/buttons/TextButton";
import GeneralSpeedometer from "@/components/graphs/speedometers/GeneralSpeedometer";
import GeneralGauge from "@/components/graphs/gauges/GeneralGauge";
import PerformanceAnalysis from "@/views/pages/performance-analysis/PerformanceAnalysis.vue";

import producersService from "@/domain/producers/producers";
import farmsService from "@/domain/farms/farms";
import harvestsService from "@/domain/harvests/harvests";
import iqlService from "@/domain/records/iql-calculations/iql-calculations";
import fertilityService from "@/domain/records/fertility-records/fertility-records";
import financialService from "@/domain/records/financial-records/financial-records";
import managementService from "@/domain/management/management";
import culturesService from "@/domain/cultures/cultures";

import RankingsProducer from "@/views/pages/rankings/RankingsProducer.vue";

import { getItem } from "@/services/local-storage.service";
import ProductivityGraph from "../cultivars/ProductivityGraph.vue";

export default {
  name: "Panel",
  props: {
    authority: {
      type: String,
      required: true,
    },
    permissions: {
      required: true,
    },
  },
  components: {
    SearchBar,
    CustomH1,
    SimpleSelect,
    RankingsProducer,
    GeneralSpeedometer,
    GeneralGauge,
    PerformanceAnalysis,
    TextButton,
    ProductivityGraph
  },
  data() {
    return {
      loader: null,
      searched: false,
      filtering: false,

      producers: [],
      producer_id: null,
      selectedProducer: {},
      loadingProducers: false,

      farms: [],
      selectedFarm: {},
      farmDetails: {},
      loadingFarms: false,

      cultures: [],

      harvests: [],
      selectedHarvest: {},
      loadingHarvests: false,

      farmData: {
        financialRecord: {},
        fertilityRecord: {},
        managementRecord: {},
        generalIqlCalculation: {},
        nationalIqlCalculation: {},
        regionalIqlCalculation: {},
        harvestIndexes: {},
      },
      loadingData: false,

      selectedIql: 'generalIql',
      gaugeComponent: 0,

      filters: {
        is_sower: [],
        farm_size: [],
        area_type: [],
        irrigation: [],
      },

      filterModal: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.producer_id = getItem("producerId");
      this.initialize();
    }, 1);
  },
  computed: {
    loading() {
      return (
        this.loadingFarms ||
        this.loadingProducers ||
        this.loadingHarvests ||
        this.loadingData
      );
    },
    selectedIqlFarmValue() {
      if (this.selectedIql === 'generalIql') return this.farmData.generalIqlCalculation.generalIql;
      if (this.selectedIql === 'financialIql') return this.farmData.financialRecord.recordIql;
      if (this.selectedIql === 'fertilityIql') return this.farmData.fertilityRecord.recordIql;
      if (this.selectedIql === 'managementIql') return this.farmData.managementRecord?.data[2]?.managementFarmIql;

      return 0;
    }
  },
  methods: {
    async initialize() {
      try {
        await Promise.all([
          this.loadHarvests(),
          this.loadProducers(),
          this.loadCultures()
        ]);
        this.selectedHarvest = this.getCurrentHarvest();

        if (this.authority === "producer") {
          this.producer_id = this.producers[0].producer_id;
          await Promise.all([this.loadFarms(), this.loadBiggestFarm()]);
          this.searchFarmData();
        }
      } catch (err) {
        console.error(err);
      }
    },
    handleLoading(value) {
      if (!value) {
        this.loader.hide();
      } else {
        this.loader = this.$loading.show();
      }
    },
    async loadProducers() {
      this.loadingProducers = true;
      try {
        const { data } = await producersService.all();
        this.producers = data;
      } catch (err) {
        console.error(err);
      } finally {
        this.loadingProducers = false;
      }
    },
    async loadFarms() {
      this.farms = [];
      this.loadingFarms = true;
      this.searched = false;
      this.selectedFarm = {};
      try {
        const producerId =
          this.producer_id || this.selectedProducer.producer_id;
        const { data } = await farmsService.listByProducer(producerId);
        this.farms = data;
      } catch (err) {
        console.error(err);
      } finally {
        this.loadingFarms = false;
      }
    },
    async loadCultures() {
      try {
        const { data } = await culturesService.all();
        this.cultures = data;
      } catch ({ response }) {
        const { error } = response.data;
        this.$toasted.show(error, {
          type: "error",
        });
      }
    },
    async loadFarmDetails() {
      this.loadingFarms = true;
      try {
        const { data } = await farmsService.getFarmDetails(
          this.selectedFarm.id
        );
        this.farmDetails = data;
        this.filters.area_type = [data.producer.area_type.toString()]
        this.filters.irrigation =[data.producer.irrigation]
        this.filters.is_sower = [data.producer.is_sower]
        this.filters.farm_size = [data.producer.farm_size]
      } catch (err) {
        console.error(err);
      } finally {
        this.loadingFarms = false;
      }
    },
    async loadBiggestFarm() {
      const { data } = await farmsService.getBiggestFarm();
      if (data) this.selectedFarm = data;
    },
    async loadHarvests() {
      this.harvests = [];
      this.loadingHarvests = true;

      try {
        const { data } = await harvestsService.all();
        this.harvests = data;
      } catch (err) {
        console.error(err);
      } finally {
        this.loadingHarvests = false;
      }
    },
    getCurrentHarvest() {
      const date = new Date();
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      let currentHarvest;

      // safra (ano da colheita) vai de agosto a julho
      if ([8, 9, 10, 11, 12].includes(month)) {
        currentHarvest = year + 1;
      } else if ([1, 2, 3, 4, 5, 6, 7].includes(month)) {
        currentHarvest = year;
      }
      if (
        this.harvests.map((harvest) => harvest.harvest).includes(currentHarvest)
      )
        return currentHarvest;

      return this.harvests.slice(-1)[0].harvest;
    },
    async searchFarmData() {
      if (!this.selectedFarm || Object.keys(this.selectedFarm).length === 0) {
        return this.$toasted.show("É necessário selecionar uma fazenda.", {
          type: "error",
          position: "bottom-right",
        });
      }
      if(!this.filtering) await this.loadFarmDetails();
      this.filtering = false;
      if (!this.selectedHarvest) {
        this.$toasted.show("É necessário selecionar uma safra.", {
          type: "error",
          position: "bottom-right",
        });
      }
      this.loadingData = true;
      this.gaugeComponent++;
      try {
        await Promise.allSettled([
          this.getIqlCalculations(),
          this.getFinancialRecord(),
          this.getFertilityRecord(),
          this.getManagementRecord(),
        ])
      } catch (err) {
        this.$toasted.show("Algo deu errado", {
          type: "error",
        });
      } finally {
        this.loadingData = false;        
        this.searched = true;
      }
    },
    async getIqlCalculations() {
      return Promise.allSettled([this.getGeneralIql(),
      this.getRegionalIql(),
      this.getNationalIql(),])
    },
    async getGeneralIql() {
      try {
        const res = await iqlService.getGeneral(
          this.selectedFarm.id,
          this.selectedHarvest
        );
        this.farmData.generalIqlCalculation = res
      } catch ({ response }) {
        const { error } = response.data;

        this.$toasted.show(error, {
          type: "error",
        });
      }
    },
    async getRegionalIql() {
      try {
        const res = await iqlService.getRegional(
          this.farmDetails.producer.responsible_success_advisor.id,
          this.selectedHarvest, this.filters
        );
        this.farmData.regionalIqlCalculation = res
      } catch ({ response }) {
        const { error } = response.data;

        this.$toasted.show(error, {
          type: "error",
        });
      }
    },
    async getNationalIql() {
      try {
        const res = await iqlService.getNational(this.selectedHarvest, this.filters)
        this.farmData.nationalIqlCalculation = res
      } catch ({ response }) {
        const { error } = response.data;

        this.$toasted.show(error, {
          type: "error",
        });
      }
    },
    async getFinancialRecord() {
      try {
        const res = await financialService
          .get(this.selectedFarm.id, this.selectedHarvest)
        this.farmData.financialRecord = res;
      } catch ({ response }) {
        const { error } = response.data;

        this.$toasted.show(error, {
          type: "error",
        });
      }
    },
    async getFertilityRecord() {
      try {
        const res = await fertilityService
          .get(this.selectedFarm.id, this.selectedHarvest);

        this.farmData.fertilityRecord = res;
      } catch ({ response }) {
        const { error } = response.data;

        this.$toasted.show(error, {
          type: "error",
        });
      }
    },
    async getManagementRecord() {
      try {
        const res = await managementService
          .getFarmManagementIql(
            this.selectedFarm.id,
            this.selectedHarvest,
            1,
            this.filters,
          );
        this.farmData.managementRecord = res;
      }
      catch ({ response }) {
        const { error } = response.data;

        this.$toasted.show(error, {
          type: "error",
        });
      }
    },
    generateRandomString(len, charSet) {
      len = len || 6;
      charSet =
        charSet || "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
      let randomString = "";
      for (let i = 0; i < len; i++) {
        const randomPoz = Math.floor(Math.random() * charSet.length);
        randomString += charSet.substring(randomPoz, randomPoz + 1);
      }
      return randomString;
    },
    saveFilters() {
      this.filtering = true;
      this.searchFarmData();
      this.filterModal = false;
    }
  },
  watch: {
    loading: {
      handler: "handleLoading",
    },
  },
};
</script>

<style scoped>
.grid-gauge {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.custom-container {
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  padding: 1.5rem 2rem 0.75rem 2rem;
}

.grey-scale {
  filter: grayscale(93%)
}
</style>
