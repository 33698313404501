<template>
  <div class="home">
    <panel class="mt-4" :authority="authoritySlug" :permissions="permissions"></panel>
  </div>
</template>

<script>
import Panel from "./pages/panels/Panel";
import {getItem} from '@/services/local-storage.service';
export default {
  name: "Home",
  components: {
    Panel,
  },
  data() {
    return {
      authoritySlug: '',
      permissions: ''
    }
  },
  methods: {
    initialize() {
      this.authoritySlug = getItem('authoritySlug');
      this.permissions = JSON.parse(getItem('permissions'));
    },
  },
  beforeMount() {
    this.initialize();
  }
};
</script>
