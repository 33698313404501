<template>
  <v-card class="d-flex align-center flex-column">
    <vue-gauge :refid="uniqueId" :options="options" />
    <custom-label16 class="gauge-label mt-n8" :label="outerText" />
  </v-card>
</template>

<script>
import CustomLabel16 from "@/components/texts/CustomLabel16";
import numberPatternMixin from "@/support/utils/numberPattern.mixin";
export default {
  name: "GeneralGauge",
  mixins: [numberPatternMixin],
  components: { CustomLabel16 },
  props: {
    value: {
      type: Number,
      required: true,
    },
    outerText: {
      type: String,
      required: true,
    },
    uniqueId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      options: {
        arcColors: this.getColors(),
        arcDelimiters:
          this.value > 0
            ? this.value * 10 >= 100
              ? [1, 99]
              : [1, this.value * 10]
            : [1, 1],
        hasNeedle: false,
        centralLabel:
          this.value > 0
            ? this.decimalPattern(this.value.toString()) || this.value
            : "0.0",
        chartWidth: 150,
        rangeLabel: ["", ""],
      },
    };
  },
  methods: {
    getColors() {
      if (this.value <= 6 && this.value >= 0) {
        return ["lightgrey", "#E21D25", "lightgrey"];
      } else if (this.value > 6 && this.value < 8) {
        return ["lightgrey", "#CFA93E", "lightgrey"];
      } else if (this.value >= 8) {
        return ["lightgrey", "#6AD72D", "lightgrey"];
      }
    },
  },
};
</script>

<style scoped>
.gauge-label {
  font-size: 22px !important;
  color: #d5d5d5 !important;
  opacity: 0.8 !important;
}
</style>
