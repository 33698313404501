<template>
  <v-container class="my-2 custom-container">
    <v-row>
      <v-col cols="12" md="8" class="d-flex justify-start col-8">
        <custom-card-title
          class="grey--text text--darken-2"
          :label="`Ranking IQL | ${farmName}`"
        />
      </v-col>

      <v-col>
        <simple-select
          :items="filter"
          v-model="selectedFilter"
          class="mr-4"
          itemText="text"
          itemValue="value"
          height="auto"
          :menuProps="{ bottom: true, offsetY: true, closeOnClick: true }"
          @custom-change="changeCoverage()"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <custom-label label="IQL Geral" class="ml-13 ml-md-5" />
        <br />

        <span class="card-ranking">
          <p class="text-ranking text-center">
            {{
              Number.isInteger(rankingProducer.ranking_general_iql)
                ? rankingProducer.ranking_general_iql + "º"
                : "-"
            }}
          </p>

          <custom-label
            v-if="rankingProducer.general_pool"
            :label="`/${rankingProducer.general_pool}`"
            class="pool-size"
          />
        </span>
      </v-col>

      <v-col>
        <custom-label label="IQL Manejo" class="ml-13 ml-md-5" />
        <br />

        <span class="card-ranking">
          <p class="text-ranking text-center">
            {{
              Number.isInteger(rankingProducer.ranking_management_iql)
                ? rankingProducer.ranking_management_iql + "º"
                : "-"
            }}
          </p>

          <custom-label
            v-if="rankingProducer.management_pool"
            :label="`/${rankingProducer.management_pool}`"
            class="pool-size"
          />
        </span>
      </v-col>

      <v-col>
        <custom-label label="IQL Fertilidade" class="ml-13 ml-md-5" />
        <br />

        <span class="card-ranking">
          <p class="text-ranking text-center">
            {{
              Number.isInteger(rankingProducer.ranking_fertility_iql)
                ? rankingProducer.ranking_fertility_iql + "º"
                : "-"
            }}
          </p>

          <custom-label
            v-if="rankingProducer.fertility_pool"
            :label="`/${rankingProducer.fertility_pool}`"
            class="pool-size"
          />
        </span>
      </v-col>

      <v-col>
        <custom-label label="IQL Financeiro" class="ml-13 ml-md-5" />
        <br />

        <span class="card-ranking">
          <p class="text-ranking text-center">
            {{
              Number.isInteger(rankingProducer.ranking_financial_iql)
                ? rankingProducer.ranking_financial_iql + "º"
                : "-"
            }}
          </p>

          <custom-label
            v-if="rankingProducer.financial_pool"
            :label="`/${rankingProducer.financial_pool}`"
            class="pool-size"
          />
        </span>
      </v-col>
    </v-row>

    <br />
  </v-container>
</template>

<script>
import CustomLabel from "@/components/texts/CustomLabel";
import CustomCardTitle from "@/components/texts/CustomCardTitle";
import SimpleSelect from "@/components/inputs/SimpleSelect";
import Rankings from "@/domain/rankings/rankings";

export default {
  name: "RankingsProducer",

  props: {
    farm: {
      type: Object,
      required: true,
    },

    harvest: {
      type: Number,
      required: true,
    },

    loading: {
      type: Boolean,
      requide: true,
    },
  },

  components: {
    CustomLabel,
    CustomCardTitle,
    SimpleSelect,
  },

  data: () => ({
    selectedFilter: "Nacional",

    filter: [
      { value: "Nacional", text: "Ranking Nacional" },
      { value: "Regional", text: "Ranking Regional" },
      { value: "Estadual", text: "Ranking Estadual" },
    ],

    rankingService: null,
    rankingProducer: {},
  }),
  computed: {
    farmName() {
      return this.farm.name;
    },
  },
  watch: {
    farm: {
      handler() {
        this.changeCoverage();
      },
      deep: true,
    },
    harvest: {
      handler() {
        this.changeCoverage();
      },
      deep: true,
    },
  },
  methods: {
    async getNationalRanking() {
      this.rankingProducer = {};

      await this.rankingService
        .getNationalProducerRanking(this.farm.id, this.harvest)
        .then((result) => {
          this.rankingProducer = Object.assign({}, result);
        })
        .catch(({ response }) => {
          const { error } = response.data;
          this.$toasted.show(error, {
            type: "error",
          });
        });
    },

    async getRegionalRanking() {
      this.rankingProducer = {};

      await this.rankingService
        .getRegionalProducerRanking(this.farm.id, this.harvest)
        .then((result) => {
          this.rankingProducer = Object.assign({}, result);
        })
        .catch(({ response }) => {
          const { error } = response.data;
          this.$toasted.show(error, {
            type: "error",
          });
        });
    },

    async getStateRanking() {
      this.rankingProducer = {};

      await this.rankingService
        .getStateProducerRanking(this.farm.state_id, this.farm.id, this.harvest)
        .then((result) => {
          this.rankingProducer = Object.assign({}, result);
        })
        .catch(({ response }) => {
          const { error } = response.data;
          this.$toasted.show(error, {
            type: "error",
          });
        });
    },

    async changeCoverage() {
      const loader = this.loading ? false : this.$loading.show();

      this.selectedFilter === "Nacional" && (await this.getNationalRanking());
      this.selectedFilter === "Regional" && (await this.getRegionalRanking());
      this.selectedFilter === "Estadual" && (await this.getStateRanking());

      if (loader) loader.hide();
    },

    initialize() {
      this.rankingService = Rankings;
      this.getNationalRanking();
    },
  },

  beforeMount() {
    this.initialize();
  },
};
</script>

<style scoped>
.custom-container {
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  padding: 1.5rem 2rem 0.75rem 2rem;
}

.card-ranking {
  position: relative;
  display: block;
  margin: auto;
  margin-top: 0.5rem;
  width: 135px;
  height: 58px;
  background: #ededed 0% 0% no-repeat padding-box;
  border: 2px solid #cfa545;
  border-radius: 14px;
  align-self: center;
}

.text-ranking {
  padding-top: 0.8rem;
  font: normal normal bold 35px/30px PT Sans;
  letter-spacing: 0.28px;
  color: #212020;
}

.pool-size {
  position: absolute;
  bottom: -50px;
  right: -25px;
}
</style>
