<template>
  <h1
      class="pa-0 font-weight-bold text-sm-left mb-8"
  >
    {{ label }}
  </h1>
</template>

<script>
export default {
  name: "CustomH1",
  props: {
    label: {
      type: String,
      required: true,
    }
  }
}
</script>

<style scoped>
  h1 {
    font-size: 28px;
    opacity: 1;
  }
</style>