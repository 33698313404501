<template>
  <v-container>
    <v-row>
      <v-col>
        <custom-card-title
          class="grey--text text--darken-2"
          label="Espectro de Performance"
      /></v-col>
      <v-col>
        <simple-select
          placeholder="Fazenda"
          :items="farms"
          v-model="selectedFarm"
          @input="getPerformance"
          itemText="name"
          returnObject
          height="auto" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="5">
        <simple-select
          placeholder="Registro"
          :items="registers"
          v-model="selectedFilter"
          @input="getPerformance"
          itemText="text"
          itemValue="value"
          height="auto" />
        <simple-select
          v-if="selectedFilter === 'Registro de Manejo'"
          class="my-2"
          placeholder="Registro"
          :items="managementPhases"
          v-model="selectedManagementPhase"
          @input="getPerformance"
          itemText="text"
          itemValue="value"
          height="auto" />
      </v-col>
      <v-col cols="7" md="4">
        <simple-select
          placeholder="Tipo"
          :items="coverages"
          v-model="selectedCoverage"
          @input="getPerformance"
          itemText="text"
          itemValue="text"
          height="auto" />
      </v-col>
      <v-col cols="5" md="3">
        <simple-select
          placeholder="Safra"
          :items="harvests"
          v-model="selectedHarvest"
          @input="getPerformance"
          height="auto" />
      </v-col>
    </v-row>
    <v-container v-if="!loading" class="mt-6">
      <v-row class="d-flex justify-center">
        <spectre-chart :series="series" :categories="categories" />
      </v-row>
    </v-container>
    <v-container v-else class="d-flex justify-center my-6">
      <v-progress-circular
        :size="70"
        :width="7"
        color="primary"
        indeterminate></v-progress-circular>
    </v-container>
  </v-container>
</template>

<script>
import CustomCardTitle from "@/components/texts/CustomCardTitle";
import SimpleSelect from "@/components/inputs/SimpleSelect";
import SpectreChart from "@/components/graphs/spectre/Spectre";

//Domain
import RankingPerformanceAnalysis from "@/domain/ranking-performance-analysis/ranking-performance-analysis";

export default {
  name: "PerfomanceSpectre",

  props: {
    farms: [],
    harvests: [],
    filteredFarm:null,
    initialData:{},
    filters:{},
  },

  components: {
    CustomCardTitle,
    SimpleSelect,
    SpectreChart,
  },

  data: () => ({
    //Services
    performanceService: null,

    //Selected Filters
    selectedFilter: "Registro de Fertilidade",
    selectedCoverage: "Nacional",
    selectedHarvest: 2023,
    selectedManagementPhase: null,
    selectedFarm:null,

    //Filters and control data
    performanceData: null,
    registers: [
      "Registro de Manejo",
      "Registro de Fertilidade",
      "Registro Financeiro",
    ],
    coverages: [
      { text: "Nacional" },
      { text: "Regional" },
      { text: "Estadual" },
    ],
    managementPhases: [
      { value: 3, text: "Pós-Plantio" },
      { value: 4, text: "Pós-Florescimento" },
      { value: 5, text: "Colheita" },
    ],
    loading:false,

    //Graph data
    dataToBuild: {},
    series: [],
    categories: [],
  }),
  methods: {
    initialize() {
      this.performanceService = RankingPerformanceAnalysis;
      this.selectedFarm = this.filteredFarm;
      this.buildDataToChart(this.filterFarms());
    },

    async getPerformance() {
      let farmsSelected=[];
      this.loading = true;
      if (!(this.selectedFilter && this.selectedCoverage && this.selectedHarvest)
        || (this.selectedFilter ==="Registro de Manejo" && !this.selectedManagementPhase))
        return;

      farmsSelected = this.filterFarms()

      try{
        const data = {
          farm_id: farmsSelected[0].id,
          harvest: this.selectedHarvest,
          phase_id: this.selectedManagementPhase || 3,
          coverage: this.selectedCoverage,
        };

        await this.performanceService.getRankingData(data, this.filters).then((result) => {
          this.performanceData = result.data;
        })
          this.buildDataToChart(farmsSelected);
        }
      catch({response}){
        this.$toasted.show(response.error, {
          type: "error",
          position: "top-right",
        });
      }
      finally{
        this.loading = false;
      }
    },

    buildDataToChart(farms) {
      const data = this.performanceData || this.initialData;

      this.categories = [];
      this.series = [];

      let farmSeries = [],
        coverageSeries = {
          name: this.selectedCoverage,
          data: [],
        };

      farms.forEach((farm) => {
        farmSeries.push({
          name: farm.name,
          id:farm.id,
          data: [],
        });
      });

      this.dataToBuild =
        this.selectedFilter === "Registro de Manejo"
          ? data.managementIndexes.original
          : this.selectedFilter === "Registro de Fertilidade"
          ? data.fertilityIndexes.original
          : this.selectedFilter === "Registro Financeiro"
          ? data.financialIndexes.original
          : null;

      console.log(this.dataToBuild)

      this.dataToBuild.forEach((index) => {
        this.categories.push(index.index_name);
        let farmIdWithrecords = index.farms.map(farm =>farm.farm_id)

        farmSeries.forEach((farm)=>{
          if(farmIdWithrecords.includes(farm.id)){
            farm.data.push(index.farms.find(_farm =>_farm.farm_id === farm.id).avg)
          } else{
            farm.data.push(0)
          }
        })
        coverageSeries.data.push(index.avg_total);
      });

      this.series = farmSeries;
      this.series.push(coverageSeries);
    },
    filterFarms(){
      if (this.selectedFarm.id !== "T")
        return this.farms.filter(farm=> farm.id === this.selectedFarm.id)
      else
        return this.farms.filter(farm=> farm.id !== this.selectedFarm.id)
    }
  },
  beforeMount() {
    this.initialize();
  },
};
</script>

<style scoped>
.custom-container {
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  padding: 1.5rem 2rem 0.75rem 2rem;
}

.card-ranking {
  display: block;
  margin-top: 0.5rem;
  width: 135px;
  height: 58px;
  background: #ededed 0% 0% no-repeat padding-box;
  border: 2px solid #cfa545;
  border-radius: 14px;
}

.text-ranking {
  padding-top: 0.8rem;
  font: normal normal bold 35px/30px PT Sans;
  letter-spacing: 0.28px;
  color: #212020;
}
</style>
