import IqlCalculationsService from "./iql-calculations.service";

class IqlCalculation {
    constructor(payload = {}) {
        Object.assign(this, {
            ...IqlCalculation.build(payload)
        });
    }

    static build(
        {
            id = '',
            grade = '',
            harvest = '',
            field_id = '',
            field = {},
            farm_id = '',
            farm = {},
            indexes = {},
            producer_id = '',
            producer = '',
            farmDetails = {},
            farmsByProducer = {},
            fields_by_farm = {},
            culture = '',
            recordDate = '',
            recordIql = '',
            generalIql = '',
            nationalIql = '',
            regionalIql = '',
            iql_record_field = '',
            created_at = '',
            updated_at = ''
        }
    ) {
        return {
            id,
            grade,
            harvest,
            field_id,
            field,
            farm_id,
            farm,
            indexes,
            producer_id,
            producer,
            farmDetails,
            farmsByProducer,
            fields_by_farm,
            culture,
            recordDate,
            recordIql,
            generalIql,
            nationalIql,
            regionalIql,
            iql_record_field,
            created_at,
            updated_at
        }
    }

    static newData(payload) {
        return new IqlCalculation(payload);
    }

    static getGeneral(farmId, harvest) {
        return new Promise((resolve, reject) => {
            IqlCalculationsService
                .getGeneral(farmId, harvest)
                .then(
                    success => {
                        const iqlCalculation = this.newData(success.data.data);
                        resolve(iqlCalculation);
                    },

                    error => {
                        reject(error);
                    }
                );
        });
    }

    static getNational(harvest, filters) {
        return new Promise((resolve, reject) => {
            IqlCalculationsService
                .getNational(harvest, filters)
                .then(
                    success => {
                        const iqlCalculation = (success.data.data);
                        resolve(iqlCalculation);
                    },

                    error => {
                        reject(error);
                    }
                );
        });
    }

    static getRegional(successAdvisorId, harvest, filters) {
        return new Promise((resolve, reject) => {
            IqlCalculationsService
                .getRegional(successAdvisorId, harvest, filters)
                .then(
                    success => {
                        const iqlCalculation = (success.data.data);
                        resolve(iqlCalculation);
                    },

                    error => {
                        reject(error);
                    }
                );
        });
    }
}

export default IqlCalculation;
