import RankingsService from "./rankings.service";

class Ranking {
  constructor(payload = {}) {
    Object.assign(this, {
      ...Ranking.build(payload),
    });
  }

  static build(
    {
      farm_id        = "",
      farm           = "",
      harvest        = "",
      state_id       = "",
      state          = "",
      producer       = "",
      farm_iql       = "",
      fertility_iql  = "",
      financial_iql  = "",
      management_iql    = "",
      indexes_fertility   = '',
      indexes_financial   = '',
      indexes_management  = '',
    }
  ) {
    return {
      farm_id,
      farm,
      harvest,
      state_id,
      state,
      producer,
      farm_iql,
      fertility_iql,
      financial_iql,
      management_iql,
      indexes_fertility,
      indexes_financial,
      indexes_management,
    };
  }

  static newData(payload) {
    return new Ranking(payload);
  }

  static getNationalRanking(harvest, filters) {
    return new Promise((resolve, reject) => {
      RankingsService.getNationalRanking(harvest, filters).then(
        (success) => {
          const national = success;
          let response   = [];

          national.data.data.forEach((item) => {
            response.push(this.newData(item));
          });
          
          resolve(response);
        },

        (error) => {
          reject(error);
        }
      );
    });
  }

  static getRegionalRanking(as, harvest, filters) {
    return new Promise((resolve, reject) => {
      RankingsService.getRegionalRanking(as, harvest, filters).then(
        (success) => {
          const regional = success;
          let response   = [];

          regional.data.data.forEach((item) => {
            response.push(this.newData(item));
          });

          resolve(response);
        },

        (error) => {
          reject(error);
        }
      );
    });
  }

  static getStateRanking(stateId, harvest, filters) {
    return new Promise((resolve, reject) => {
      RankingsService.getStateRanking(stateId, harvest, filters).then(
        (success) => {
          const state = success;
          let response   = [];

          state.data.data.forEach((item) => {
            response.push(this.newData(item));
          });

          resolve(response);
        },

        (error) => {
          reject(error);
        }
      );
    });
  }

  static getNationalProducerRanking(farmId, harvest) {
    return new Promise((resolve, reject) => {
      RankingsService.getNationalProducerRanking(farmId, harvest).then(
        (success) => {
          const national = success;
          const response = national.data.data;
          resolve(response);
        },

        (error) => {
          reject(error);
        }
      );
    });
  }

  static getRegionalProducerRanking(farmId, harvest) {
    return new Promise((resolve, reject) => {
      RankingsService.getRegionalProducerRanking(farmId, harvest).then(
        (success) => {
          const regional = success;
          const response = regional.data.data;
          resolve(response);
        },

        (error) => {
          reject(error);
        }
      );
    });
  }

  static getStateProducerRanking(stateId, farmId, harvest) {
    return new Promise((resolve, reject) => {
      RankingsService.getStateProducerRanking(stateId, farmId, harvest).then(
        (success) => {
          const state    = success;
          const response = state.data.data;
          resolve(response);
        },

        (error) => {
          reject(error);
        }
      );
    });
  }
}

export default Ranking;
